import React from 'react';

import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { PROFILE_AUTH_CHANGE_PIN_ROUTE, PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';
import useFirebase from 'utils/useFirebase.hook';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { Button, Flex, Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './ProfileAuth.module.scss';

const ProfileAuth = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { unregister } = useFirebase();
  const [, setSelectedEmployee] = useFullSelectedEmployee();

  const handlePinChange = () => {
    navigate(PROFILE_AUTH_CHANGE_PIN_ROUTE);
  };

  const handleLogout = async () => {
    unregister();
    await auth.signoutSilent();
    setSelectedEmployee('');
  };

  const headerChildren = (
    <>
      <Header.GoBack to={PROFILE_ROUTE} />
      <Header.Label label={t('profile_auth_settings_short')} />
    </>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card>
            <Flex fullWidth vertical gap="24">
              <Card.Default
                title={t('profile_auth_settings')}
                description={t('profile_auth_settings_description')}
                icon="authSettings"
                iconPadding="8"
              />
              <Button
                leftIcon="passwordHorizontal"
                onClick={handlePinChange}
                size="large"
                fullWidth>
                {t('profile_auth_change_pin')}
              </Button>
            </Flex>
          </Card>
          <button onClick={handleLogout} className={s.logout}>
            <Icon name="logout" />
            <Typography.Text size="14" weight="500">
              {t('home_finish_session')}
            </Typography.Text>
          </button>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default ProfileAuth;
