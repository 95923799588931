import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDocumentsTasksDetailsRoute } from 'routes/documents/list';
import { t } from 'tools/i18n';
import { fetchMoreData } from 'utils/fetchMoreData';

import { Icon, Segmented, SegmentedProps, Typography } from 'gazprom-ui-lib';

import UniversalLoader from 'components/universal-loader';
import Card from 'containers/card';
import useAssignmentConfig from 'containers/document-assignment/useAssignmentConfig.hook';
import WithInfiniteScroll from 'containers/wrappers/with-infinite-scroll';
import WithLoader from 'containers/wrappers/with-loader';

import useGetData from 'pages/documents/containers/my-assignments/useGetData.hook';

import { AssignmentType } from 'types/assignment.types';
import { PaginationPropsType } from 'types/pagination.types';

import DocumentsNotFound from '../../documents-not-found';
import s from './MyAssignments.module.scss';
import { DEFAULT_PAGINATION_CONFIG, SEGMENT_OPTIONS } from './myAssignments.utils';

const MyAssignments = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [segment, setSegment] = useState<string>('');
  const [pagination, setPagination] = useState<PaginationPropsType>(DEFAULT_PAGINATION_CONFIG);

  const handleSegmentChange: SegmentedProps['onChange'] = (segment) => {
    setSegment(segment.toString());
    setPagination(DEFAULT_PAGINATION_CONFIG);
  };

  const getAssignmentConfig = useAssignmentConfig({ isList: true, detailed: true });

  const {
    assignedStatements,
    totalCount,
    assignedStatementsIsLoading,
    assignedStatementsIsFetching,
    isUninitialized,
  } = useGetData({ segment, pagination });

  const handleNavigate = (id: string) => {
    navigate(getDocumentsTasksDetailsRoute(id), { state: { type: segment } });
  };
  const renderStatement = (task: AssignmentType) => {
    const { tag, title, employee } = getAssignmentConfig(task);

    return (
      <Card.Button onClick={() => handleNavigate(task.id)} className={s.task} key={task.id}>
        <div>
          <Typography.Text type="secondary" size="12">
            {dayjs(task.createdAt).local().format('DD MMMM YYYY, HH:mm')}
          </Typography.Text>
          <div>
            <Typography.Title level={5}>{title}</Typography.Title>
          </div>
          {employee && <div className={s.employeeWrapper}>{employee}</div>}
          {tag}
        </div>
        <Icon name="forward" className={s.iconRight} />
      </Card.Button>
    );
  };

  useEffect(() => {
    if (location?.state?.type && typeof location?.state?.type === 'string') {
      setSegment(location?.state?.type);
    } else {
      setSegment(SEGMENT_OPTIONS[0].value);
    }
  }, [location?.state?.type]);

  const infiniteScrollIsFetching = assignedStatementsIsFetching || isUninitialized;
  const isFetchMoreAvailable = pagination.page * pagination.size < (totalCount ?? 0);

  const loader = (
    <div className={s.loader}>
      <UniversalLoader />
    </div>
  );

  return (
    <Card withPadding={false}>
      <div className={s.header}>
        <Typography.Title level={4}>{t('common_waiting_for_your_action')}</Typography.Title>
        <Segmented
          options={SEGMENT_OPTIONS}
          value={segment}
          onChange={handleSegmentChange}
          fullWidth
        />
      </div>
      <WithLoader isLoading={assignedStatementsIsLoading} loader={loader}>
        {assignedStatements.length ? (
          <WithInfiniteScroll
            fetchMoreData={fetchMoreData(setPagination)}
            isFetching={infiniteScrollIsFetching}
            isFetchMoreAvailable={isFetchMoreAvailable}>
            <div className={s.statementWrapper}>{assignedStatements.map(renderStatement)}</div>
          </WithInfiniteScroll>
        ) : (
          <DocumentsNotFound />
        )}
      </WithLoader>
    </Card>
  );
};

export default MyAssignments;
