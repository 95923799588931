export const MORE_ROUTE = '/more';
export const MORE_EDUCATION_ROUTE = `${MORE_ROUTE}/education`;
export const MORE_EDUCATION_BDD_ROUTE = `${MORE_EDUCATION_ROUTE}/bdd`;
export const MORE_EDUCATION_WORK_SECURITY_ROUTE = `${MORE_EDUCATION_ROUTE}/work-security`;
export const MORE_EDUCATION_FIRE_SECURITY_ROUTE = `${MORE_EDUCATION_ROUTE}/fire-security`;
export const MORE_EDUCATION_MANUFACTURING_JOBS_ROUTE = `${MORE_EDUCATION_ROUTE}/manufacturing-jobs`;

export const MORE_DMS_ROUTE = `${MORE_ROUTE}/dms`;

export const MORE_FAQ_ROUTE = `${MORE_ROUTE}/faq`;

export const MORE_FAQ_CATEGORY_ROUTE = `${MORE_FAQ_ROUTE}/category`;
export const MORE_FAQ_CATEGORY_DETAILS_ROUTE = `${MORE_FAQ_CATEGORY_ROUTE}/:faqCategoryId`;
export const getFaqCategoryDetailsRoute = (id: string | number) =>
  `${MORE_FAQ_CATEGORY_ROUTE}/${id}`;

export const MORE_FAQ_QUESTION_ROUTE = `${MORE_FAQ_ROUTE}/question`;
export const MORE_FAQ_QUESTION_DETAILS_ROUTE = `${MORE_FAQ_QUESTION_ROUTE}/:faqQuestionId`;
export const getFaqQuestionDetailsRoute = (id: string | number) =>
  `${MORE_FAQ_QUESTION_ROUTE}/${id}`;

export const MORE_SURVEYS_ROUTE = `${MORE_ROUTE}/surveys`;
export const MORE_SURVEY_ROUTE = `${MORE_SURVEYS_ROUTE}/:anketologId/:revisionId/:anketaLinkId`;
export const getMoreSurveyRoute = (anketologId: string, revisionId: number, anketaLinkId: string) =>
  `${MORE_SURVEYS_ROUTE}/${anketologId}/${revisionId}/${anketaLinkId}`;

export const MORE_FEEDBACK_ROUTE = `${MORE_ROUTE}/feedbacks`;
export const MORE_FEEDBACK_CREATE_ROUTE = `${MORE_FEEDBACK_ROUTE}/create`;
export const MORE_FEEDBACK_DETAILS_ROUTE = `${MORE_FEEDBACK_ROUTE}/:id`;
export const getProfileFeedbackDetailsRoute = (id: string | number) =>
  `${MORE_FEEDBACK_ROUTE}/${id}`;

export const MORE_ABOUT_COMPANY_ROUTE = `${MORE_ROUTE}/about-company`;
