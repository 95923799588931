import React, { FC, useState } from 'react';

import { t } from 'tools/i18n';

import { Card, Divider, Flex, Icon, Typography } from 'gazprom-ui-lib';

import ApprovalItem from 'containers/approval-item';

import { ApprovalMapItemType } from 'types/businessTrip.types';

import s from './ApprovalCard.module.scss';
import { getFirstLastName } from './approvalCard.utils';

interface Props {
  cycleNumber: string;
  approvals: ApprovalMapItemType[];
  isExpandedDefault?: boolean;
}

const ApprovalCard: FC<Props> = (props) => {
  const { cycleNumber, approvals, isExpandedDefault } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(!!isExpandedDefault);

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card withPadding={false}>
      <Flex className={s.header} justify="space-between" align="center" onClick={handleExpand}>
        <Typography.Title level={3}>
          {`${t('documents_business_trip_approval_cycle')} ${cycleNumber}`}
        </Typography.Title>
        <Icon color="var(--color-primary-6)" name={isExpanded ? 'up' : 'down'} />
      </Flex>
      {isExpanded && (
        <Flex vertical className={s.content}>
          {approvals.map((approval, index) => {
            const { approvalStatus, dateSolution, ...otherProps } = approval;

            const { firstName, lastName } = getFirstLastName(approval.fio);

            return (
              <Flex vertical>
                <Divider />
                <ApprovalItem
                  key={index}
                  withPadding
                  firstName={firstName}
                  lastName={lastName}
                  status={approvalStatus}
                  finishDate={dateSolution ?? ''}
                  {...otherProps}
                />
              </Flex>
            );
          })}
        </Flex>
      )}
    </Card>
  );
};

export default ApprovalCard;
