import { t } from 'tools/i18n';

import { AssignmentWorkTourType } from 'types/assignment.types';

export type WorkTourAttributeType = {
  attributeKey: keyof Pick<
    AssignmentWorkTourType,
    | 'dateFrom'
    | 'dateTo'
    | 'destination'
    | 'divisionName'
    | 'duration'
    | 'target'
    | 'sourceFinancing'
  >;
  attributeName: string;
  isDate?: boolean;
};

export const WORK_TOUR_ATTRIBUTES: WorkTourAttributeType[] = [
  {
    attributeKey: 'dateFrom',
    attributeName: t('documents_business_trip_date_from'),
    isDate: true,
  },
  {
    attributeKey: 'dateTo',
    attributeName: t('documents_business_trip_date_to'),
    isDate: true,
  },
  {
    attributeKey: 'destination',
    attributeName: t('common_destination'),
  },
  {
    attributeKey: 'divisionName',
    attributeName: t('common_structural_subdivision'),
  },
  {
    attributeKey: 'duration',
    attributeName: t('documents_business_trip_days_count'),
  },
  {
    attributeKey: 'target',
    attributeName: t('documents_business_trip_purpose'),
  },
  {
    attributeKey: 'sourceFinancing',
    attributeName: t('common_source_of_financing'),
  },
];
