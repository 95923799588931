import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { DOCUMENTS_CERTIFICATE_ROUTE, DOCUMENTS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';
import { createMediaDownloadUrl, createMediaUploadUrl } from 'utils/createMediaUrl.utils';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';

import { activeKedoTaskSelector } from 'selectors/kedoSlice.selector';
import { useAppSelector } from 'store';

import { Button, Icon, Typography } from 'gazprom-ui-lib';

import KedoFile from 'components/kedo-file';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { KedoTaskFile } from 'types/documents.types';

import s from './DocumentsSignList.module.scss';
import DocumentsSignListModal from './documents-sign-list-modal';
import useHandleSign from './useHandleSign.hook';

const DocumentsSignList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();

  const activeKedoTask = useAppSelector(activeKedoTaskSelector);

  const headerChildren = (
    <>
      <Header.GoBack to={DOCUMENTS_ROUTE} />
      <Header.Label label={t('documents_sign_title')} />
      <Header.RightIcon
        iconName={'documents'}
        onClick={() => navigate(DOCUMENTS_CERTIFICATE_ROUTE)}
      />
    </>
  );
  const { handleOpenFile, loadingKey } = useOpenFileByUrl();

  const renderSignItem = (item: KedoTaskFile) => {
    const { fileKey } = item;
    return (
      <KedoFile
        key={fileKey}
        file={item}
        onClick={() =>
          handleOpenFile({
            url: createMediaDownloadUrl(createMediaUploadUrl(fileKey)),
            withAuth: true,
            fileKey,
          })
        }
        disabled={!!loadingKey}
        loading={loadingKey === fileKey}
      />
    );
  };

  const { isLoading, handleSign } = useHandleSign({ setIsModalVisible });

  if (!activeKedoTask) {
    return null;
  }

  return (
    <>
      <WithStatusBar>
        <WithNavigation headerChildren={headerChildren}>
          <WithBackground>
            <Card withPadding={false}>
              <div className={s.title}>
                <Typography.Title level={4}>{t('documents_sign_list_title')}</Typography.Title>
              </div>
              {activeKedoTask.filesToSign.map(renderSignItem)}
              <div className={s.button}>
                <Button
                  rightIcon="arrowRight"
                  fullWidth
                  onClick={handleSign}
                  size="large"
                  loading={isLoading}>
                  {t('documents_sign_button')}
                </Button>
              </div>
            </Card>
            <Card className={s.tooltipCard}>
              <Icon name="info" className={s.icon} />
              <Typography.Text size="14">{t('documents_sign_tooltip')}</Typography.Text>
            </Card>
          </WithBackground>
        </WithNavigation>
      </WithStatusBar>
      <DocumentsSignListModal isVisible={isModalVisible} setIsVisible={setIsModalVisible} />
    </>
  );
};

export default DocumentsSignList;
