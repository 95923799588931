import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  DOCUMENTS_ROUTE,
  DOCUMENTS_SIGN_LIST_ROUTE,
  getDocumentsSocialPolicyDetailsDetailsRoute,
} from 'routes/documents/list';
import { getSocialPolicyProgramWithIdRoute } from 'routes/guide/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useGetKedoCategoriesQuery,
  useLazyGetKedoStatementByIdQuery,
  usePrepareKedoTaskMutation,
  useSaveDraftKedoTaskMutation,
} from 'services/kedo/kedoApiService';
import { setActiveKedoFormTask, setActiveKedoTask } from 'slices/kedo';

import { Button, FormInstance, Icon, Typography, handleRequest } from 'gazprom-ui-lib';

import Attribute, {
  AttributeCompoundedComponents,
  getAttributeComponentType,
} from 'containers/attribute';
import { AttributeDefaultProps, transformRequest } from 'containers/attribute/attribute.utils';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { SERVICE_TAGS } from 'constants/serviceTags';
import { PreparedKedoTask, TASK_TYPE_ENUM } from 'types/documents.types';

import s from './GuideSocialPolicyApply.module.scss';
import DocumentsStatementRequestModal from './documents-statement-request-modal';

const GuideSocialPolicyApply = () => {
  const location = useLocation();
  const { statementId, socialCategoryId, socialProgramId } = useParams<{
    socialProgramId: string;
    socialCategoryId: string;
    statementId: string;
  }>();
  const navigate = useNavigate();

  const [modalVisible, setModalVisible] = useState(false);

  const [selectedEmployee] = useFullSelectedEmployee();

  const [getKedoStatement, { data: statement, isLoading: isStatementLoading }] =
    useLazyGetKedoStatementByIdQuery();

  useEffect(() => {
    if (statementId && selectedEmployee) {
      getKedoStatement({
        statementId: statementId!,
        myEmployeeId: selectedEmployee?.id!,
        fromId: location?.state?.fromId,
      });
    }
  }, [getKedoStatement, location?.state?.fromId, selectedEmployee, statementId]);

  const dispatch = useDispatch();
  const { data: categories, isLoading: isCategoriesLoading } = useGetKedoCategoriesQuery({
    type: TASK_TYPE_ENUM.SOCIAL,
  });
  const [prepareTask, { isLoading: prepareTaskIsLoading }] = usePrepareKedoTaskMutation();
  const [saveDraft] = useSaveDraftKedoTaskMutation();

  const isLoading = isCategoriesLoading || isStatementLoading;

  const headerChildren = (
    <>
      <Header.GoBack to={getSocialPolicyProgramWithIdRoute(socialCategoryId!, socialProgramId!)} />
      <Header.Label label={t('guide_social_policy_filing_application')} />
    </>
  );

  const handleModalOk = () => {
    setModalVisible(false);
    navigate(DOCUMENTS_ROUTE);
  };

  const category = useMemo(() => {
    return categories?.find((category) => category.id === statement?.categoryId);
  }, [categories, statement]);

  const onFinish = <R extends Record<string, unknown>>(values: R) => {
    if (statement?.attributes && selectedEmployee) {
      dispatch(setActiveKedoFormTask(values));

      const request = {
        statementId: statementId || '',
        ...transformRequest(values, statement?.attributes),
        fromId: location?.state?.fromId,
      };

      const onSuccess = (task?: PreparedKedoTask) => {
        if (task) {
          const navigateParams = {
            state: {
              cameFrom: getDocumentsSocialPolicyDetailsDetailsRoute(task.taskId),
              modalType: 'created',
              invalidateTags: [SERVICE_TAGS.GET_KEDO_TASKS, SERVICE_TAGS.GET_ASSIGNED_TASKS],
            },
          };

          dispatch(setActiveKedoTask(task));
          navigate(DOCUMENTS_SIGN_LIST_ROUTE, navigateParams);
        }
      };

      prepareTask({
        selectedEmployee: selectedEmployee.id,
        body: request,
      })
        .then(
          handleRequest({
            onSuccess,
          }),
        )
        .catch(() => {
          setModalVisible(true);
        });
    }
  };

  const handleSaveDraft = useCallback(
    (form: FormInstance) => {
      const shouldSaveDraft = !!selectedEmployee?.id && !statement?.fromId;

      if (statement?.attributes && shouldSaveDraft) {
        let request = {
          statementId: statementId || '',
          ...transformRequest(form.getFieldsValue(), statement.attributes),
        };

        saveDraft({
          myEmployeeId: selectedEmployee.id,
          id: statement.kedoDraftId,
          body: request,
        });
      }
    },
    [saveDraft, selectedEmployee?.id, statement, statementId],
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren} showNavbar={false}>
        <WithBackground>
          {statement?.kedoDraftUpdatedAt && (
            <Card className={s.infoCard}>
              <Icon name="info" />
              <Typography.Text size="14">
                {t('documents_continue_draft', {
                  type: t('guide_social_policy_draft').toLowerCase(),
                })}
                <br /> {dayjs(statement?.kedoDraftUpdatedAt).format('DD.MM.YYYY')}
              </Typography.Text>
            </Card>
          )}
          <Attribute onFinish={onFinish} onSaveDraft={handleSaveDraft}>
            <Card>
              <WithLoader isLoading={isLoading}>
                <Typography.Text size="12" type="secondary">
                  {category?.name}
                </Typography.Text>
                <Typography.Title level={4}>{statement?.name}</Typography.Title>
                <Typography.Text size="14">{statement?.description}</Typography.Text>
              </WithLoader>
            </Card>
            {!!statement?.attributes?.length && (
              <Card>
                {statement.attributes.map((attr) => {
                  const type = getAttributeComponentType(
                    attr.type,
                  ) as keyof AttributeCompoundedComponents;

                  const Component = Attribute[type] as AttributeCompoundedComponents;

                  if (!Component) {
                    return <></>;
                  }
                  const defaultProps: AttributeDefaultProps = { ...attr };

                  if (type === 'File' || type === 'Multifile') {
                    defaultProps.files = statement.files as AttributeDefaultProps['files'];
                    defaultProps.onSaveDraft = handleSaveDraft;
                  }

                  return <Component key={attr.id} {...defaultProps} />;
                })}
              </Card>
            )}
            <div className={s.fixedCard}>
              <Button
                loading={prepareTaskIsLoading}
                htmlType="submit"
                fullWidth
                rightIcon="arrowRight"
                size="large"
                type="primary">
                {t('common_sign')}
              </Button>
            </div>
          </Attribute>
          <DocumentsStatementRequestModal isVisible={modalVisible} onClickHandler={handleModalOk} />
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default GuideSocialPolicyApply;
