import { Dispatch, SetStateAction } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { DOCUMENTS_SIGN_CODE_ROUTE } from 'routes/documents/list';

import initialApiService from 'services/initialApiService';
import {
  useRejectKedoDocumentAssignmentMutation,
  useRevisionKedoDocumentAssignmentMutation,
  useSignKedoDocumentAssignmentMutation,
} from 'services/v2/me/task-assignment/kedo-document/kedoDocumentApiService';
import { SignKedoDocumentAssignmentResponseType } from 'services/v2/me/task-assignment/kedo-document/kedoDocumentApiService.types';
import { useAppDispatch } from 'store';

import { handleRequest } from 'gazprom-ui-lib';

import { SERVICE_TAGS } from 'constants/serviceTags';
import { AssignmentType } from 'types/assignment.types';

import { MODAL_TYPE, getFilesKeys } from '../utils';
import { ModalType } from './actionsKedoDocument.utils';

interface Props {
  setModalType: Dispatch<SetStateAction<ModalType>>;
  assignment?: AssignmentType;
  handleCertificateCheck: (callback: () => void) => void;
}

const useActions = (props: Props) => {
  const { setModalType, handleCertificateCheck, assignment } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [sign, { isLoading: signIsLoading }] = useSignKedoDocumentAssignmentMutation();
  const [reject, { isLoading: rejectIsLoading }] = useRejectKedoDocumentAssignmentMutation();
  const [revision, { isLoading: revisionIsLoading }] = useRevisionKedoDocumentAssignmentMutation();

  const handleInvalidate = () => {
    dispatch(
      initialApiService?.util?.invalidateTags([
        SERVICE_TAGS.GET_ASSIGNED_TASK_BY_ID,
        SERVICE_TAGS.GET_ASSIGNED_TASKS,
      ]),
    );
  };

  const handleRevision = (formResponse: { comment: string }) => {
    if (assignment) {
      const { comment } = formResponse;

      const onSuccess = () => {
        handleInvalidate();
        setModalType(MODAL_TYPE.CORRECTION);
      };

      revision({ assignmentId: assignment.id, comment, prefix: 'REVISION' }).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const handleReject = (formResponse: { comment: string }) => {
    if (assignment) {
      const { comment } = formResponse;

      const onSuccess = () => {
        handleInvalidate();
        setModalType(MODAL_TYPE.REJECTED);
      };

      reject({ assignmentId: assignment.id, comment, prefix: 'REJECTED' }).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const handleSign = () => {
    if (assignment) {
      const onSuccess = (res?: SignKedoDocumentAssignmentResponseType) => {
        if (res) {
          const state = {
            cameFrom: location.pathname,
            type: 'v2kedoDocumentTypeSign',
            operationId: res.operationId,
            modalType: MODAL_TYPE.SIGNED,
          };

          navigate(DOCUMENTS_SIGN_CODE_ROUTE, { state });
        }
      };

      const body = {
        assignmentId: assignment.id,
        documentId: assignment.kedoDocument?.id,
        fileKeys: getFilesKeys(assignment.kedoDocument?.files ?? []),
      };

      handleCertificateCheck(() =>
        sign(body).then(
          handleRequest({
            onSuccess,
          }),
        ),
      );
    }
  };

  const isLoading = signIsLoading || rejectIsLoading || revisionIsLoading;
  return { handleSign, handleReject, handleRevision, isLoading };
};

export default useActions;
