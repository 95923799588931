import React, { FC, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Checkbox, Form, TextArea, Typography } from 'gazprom-ui-lib';

import { FORM_STATEMENT_ENUM } from 'containers/attribute/attribute.utils';
import TextAreaModal from 'containers/textarea-modal';

import { DELIVER_OPTIONS_VALUE } from 'pages/documents/documents.utils';

import s from './DocumentsCreateRequestDeliver.module.scss';
import { DELIVER_OPTIONS } from './documentsCreateRequestDeliver.utils';
import useSetDraftValueHook from './useSetDraftValue.hook';

interface DocumentsCreateRequestDeliverProps {
  draftValue?: string | null;
}

const DocumentsCreateRequestDeliver: FC<DocumentsCreateRequestDeliverProps> = (props) => {
  const { draftValue } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const form = Form.useFormInstance();
  const deliver: DELIVER_OPTIONS_VALUE[] | undefined = Form.useWatch(FORM_STATEMENT_ENUM.DELIVER);

  useSetDraftValueHook({ form, draftValue });

  const handleModalOpenClose = () => setIsModalOpen((prevState) => !prevState);

  const showAddressField = deliver?.includes(DELIVER_OPTIONS_VALUE.MAIL);
  const handleSubmitModal = (formResponse: Record<string, string>) => {
    form.setFieldValue(FORM_STATEMENT_ENUM.SHIPPING_ADDRESS, formResponse.shippingAddress);
    setIsModalOpen(false);
  };

  return (
    <>
      <Typography.Title level={5} className={s.title}>
        {t('documents_certificate_obtain')}
      </Typography.Title>
      <Form.Item className={s.item} name={FORM_STATEMENT_ENUM.DELIVER} rules={[formRules.required]}>
        <Checkbox.Group className={s.deliveryOptions} options={DELIVER_OPTIONS} />
      </Form.Item>
      {showAddressField && (
        <Form.Item name={FORM_STATEMENT_ENUM.SHIPPING_ADDRESS} rules={[formRules.required]}>
          <TextArea
            onClick={handleModalOpenClose}
            rows={6}
            placeholder={t('documents_provide_address_and_zip_code')}
            className={s.textArea}
            readOnly
          />
        </Form.Item>
      )}
      <TextAreaModal
        name={FORM_STATEMENT_ENUM.SHIPPING_ADDRESS}
        title={t('documents_recipient_address')}
        maxLength={250}
        isModalOpen={isModalOpen}
        onSubmitHandler={handleSubmitModal}
        setIsModalOpen={handleModalOpenClose}
      />
    </>
  );
};

export default DocumentsCreateRequestDeliver;
