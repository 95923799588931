import React, { FC } from 'react';

import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { DOCUMENTS_ROUTE } from 'routes/documents/list';
import { GUIDE_ROUTE } from 'routes/guide/list';
import { HOME_ROUTE } from 'routes/home/list';
import { PROFILE_ROUTE } from 'routes/profile/list';
import { t } from 'tools/i18n';

import { Icon, TIconsLiteral } from 'gazprom-ui-lib';

import s from './Navbar.module.scss';

export type RouteType = { to: string; icon: TIconsLiteral; label: string };

interface NavbarProps {
  className?: string;
  navbarConfig?: RouteType[];
}

const getClassName = ({ isActive }: { isActive: boolean }) => cn(s.link, { [s.active]: isActive });

const ROUTES: RouteType[] = [
  { icon: 'homeShine', label: 'common_main', to: HOME_ROUTE },
  { icon: 'documents', label: 'common_documents', to: DOCUMENTS_ROUTE },
  { icon: 'accountCircleFilled', label: 'common_profile', to: PROFILE_ROUTE },
  { icon: 'gid', label: 'common_guide', to: GUIDE_ROUTE },
  { icon: 'menuMore', label: 'common_more', to: '/more' },
];

const renderNavlink = (props: RouteType) => {
  const { to, icon, label } = props;

  return (
    <NavLink to={to} className={getClassName} key={`navlink__${to}__${icon}`}>
      <div className={s.halo} />
      <Icon name={icon} />
      <span>{t(label)}</span>
    </NavLink>
  );
};

const Navbar: FC<NavbarProps> = (props) => {
  const { className, navbarConfig } = props;

  const routesToRender = navbarConfig?.length ? navbarConfig : ROUTES;

  return <nav className={cn(s.wrapper, className)}>{routesToRender.map(renderNavlink)}</nav>;
};

export default Navbar;
