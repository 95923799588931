import { FileType } from 'types/file.types';

export const getFilesKeys = (files: FileType[]) => files?.map((file) => file.fileKey);

export enum MODAL_TYPE {
  SIGNED = 'SIGNED',
  FAMILIARIZED = 'FAMILIARIZED',
  REJECTED = 'REJECTED',
  APPROVE = 'APPROVE',
  CORRECTION = 'CORRECTION',
  REVISION_COMMENT = 'REVISION_COMMENT',
  REJECT_COMMENT = 'REJECT_COMMENT',
}
