import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CancelKedoTaskPropsType,
  CancelKedoTaskResponseType,
  CancelRequestPropsType,
  CancelRequestResponseType,
  CreateKedoTaskPropsType,
  CreateKedoTaskResponseType,
  GetAssignedToMeStatementByIdPropsType,
  GetAssignedToMeStatementByIdResponseType,
  GetAssignedToMeStatementsPropsType,
  GetAssignedToMeStatementsResponseType,
  GetKedoCategoriesPropsType,
  GetKedoCategoriesResponseType,
  GetKedoCategoryByIdPropsType,
  GetKedoCategoryByIdResponseType,
  GetKedoStatementByIdPropsType,
  GetKedoStatementByIdResponseType,
  GetKedoStatementPropsType,
  GetKedoStatementResponseType,
  GetKedoStatementsPropsType,
  GetKedoStatementsResponseType,
  GetKedoStatusesPropsType,
  GetKedoStatusesResponseType,
  GetKedoTaskByIdPropsType,
  GetKedoTaskByIdResponseType,
  GetKedoTaskLeadersPropsType,
  GetKedoTaskLeadersResponseType,
  GetKedoTasksDraftsPropsType,
  GetKedoTasksDraftsResponseType,
  GetKedoTasksPropsType,
  GetKedoTasksResponseType,
  GetSocialTasksWithCountersPropsType,
  GetSocialTasksWithCountersResponseType,
  PrepareKedoTaskPropsType,
  PrepareKedoTaskResponseType,
  RequestPayslipPropsType,
  RequestPayslipResponseType,
  SaveDraftKedoTaskPropsType,
  SaveDraftKedoTaskResponseType,
  SendKedoTaskPropsType,
  SendKedoTaskResponseType,
  UpdateAssignedToMeStatementByIdPropsType,
  UpdateAssignedToMeStatementByIdResponseType,
} from './kedoApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';
import { TASK_TYPE_ENUM } from 'types/documents.types';

import { createKedoUrl } from './utils';

const kedoApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getKedoCategories: builder.query<GetKedoCategoriesResponseType, GetKedoCategoriesPropsType>({
      query: (params) => ({
        url: createKedoUrl(`/me/category${prepareQueryParams(params)}`),
      }),
    }),
    getKedoCategoryById: builder.query<
      GetKedoCategoryByIdResponseType,
      GetKedoCategoryByIdPropsType
    >({
      query: (id) => ({
        url: createKedoUrl(`/me/category/${id}`),
      }),
    }),
    getKedoStatements: builder.query<GetKedoStatementsResponseType, GetKedoStatementsPropsType>({
      query: (params) => ({
        url: createKedoUrl(`/me/statement${prepareQueryParams(params)}`),
      }),
    }),
    getKedoStatementByCode: builder.query<GetKedoStatementResponseType, GetKedoStatementPropsType>({
      query: ({ code, ...params }) => ({
        url: createKedoUrl(`/me/statement/by-code/${code}${prepareQueryParams(params)}`),
      }),
    }),
    getKedoStatementById: builder.query<
      GetKedoStatementByIdResponseType,
      GetKedoStatementByIdPropsType
    >({
      query: ({ statementId, ...params }) => ({
        url: createKedoUrl(`/me/statement/${statementId}${prepareQueryParams(params)}`),
      }),
    }),
    getKedoTasks: builder.query<GetKedoTasksResponseType, GetKedoTasksPropsType>({
      query: ({ type = TASK_TYPE_ENUM.KEDO, ...params }) => ({
        url: createKedoUrl(`/me/task${prepareQueryParams(params)}&type=${type}`),
      }),
      providesTags: [SERVICE_TAGS.GET_KEDO_TASKS],
    }),
    getKedoTasksDrafts: builder.query<GetKedoTasksDraftsResponseType, GetKedoTasksDraftsPropsType>({
      query: (params) => ({
        url: createKedoUrl(`/me/draft${prepareQueryParams(params)}`),
      }),
    }),
    getKedoTaskById: builder.query<GetKedoTaskByIdResponseType, GetKedoTaskByIdPropsType>({
      query: ({ taskId, ...params }) => ({
        url: createKedoUrl(`/task/${taskId}${prepareQueryParams(params)}`),
      }),
      providesTags: (_a, _b, { taskId }) => [{ type: SERVICE_TAGS.GET_KEDO_TASK_BY_ID, id: taskId }],
    }),
    cancelKedoTask: builder.mutation<CancelKedoTaskResponseType, CancelKedoTaskPropsType>({
      query: ({ id, myEmployeeId, ...body }) => ({
        url: createKedoUrl(
          `/me/task/${id}/cancel${prepareQueryParams({ employeeId: myEmployeeId })}`,
        ),
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_a, _b, { id }) => [
        { type: SERVICE_TAGS.GET_KEDO_TASKS },
        { type: SERVICE_TAGS.GET_KEDO_TASK_BY_ID, id },
      ],
    }),
    getKedoTaskLeaders: builder.query<GetKedoTaskLeadersResponseType, GetKedoTaskLeadersPropsType>({
      query: ({ id, ...params }) => ({
        url: createKedoUrl(`/me/task/${id}/leaders${prepareQueryParams(params)}`),
      }),
    }),
    createKedoTask: builder.mutation<CreateKedoTaskResponseType, CreateKedoTaskPropsType>({
      query: ({ selectedEmployee, body }) => ({
        url: createKedoUrl(`/me/task?myEmployeeId=${selectedEmployee}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_KEDO_TASKS, SERVICE_TAGS.GET_ASSIGNED_TASKS],
    }),
    prepareKedoTask: builder.mutation<PrepareKedoTaskResponseType, PrepareKedoTaskPropsType>({
      query: ({ selectedEmployee, body }) => ({
        url: createKedoUrl(`/me/task/prepare?myEmployeeId=${selectedEmployee}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_KEDO_TASKS],
    }),
    saveDraftKedoTask: builder.mutation<SaveDraftKedoTaskResponseType, SaveDraftKedoTaskPropsType>({
      query: ({ myEmployeeId, id, body }) => ({
        url: createKedoUrl(`/me/draft/${id}?myEmployeeId=${myEmployeeId}`),
        method: 'PATCH',
        body,
      }),
    }),
    sendKedoTask: builder.mutation<SendKedoTaskResponseType, SendKedoTaskPropsType>({
      query: ({ selectedEmployee, id, body }) => ({
        url: createKedoUrl(`/me/task/${id}/send?myEmployeeId=${selectedEmployee}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_KEDO_TASKS],
    }),
    requestPayslip: builder.query<RequestPayslipResponseType, RequestPayslipPropsType>({
      query: ({ myEmployeeId, ...body }) => ({
        method: 'POST',
        url: createKedoUrl(`/me/payslip?myEmployeeId=${myEmployeeId}`),
        body,
      }),
    }),
    cancelRequest: builder.mutation<CancelRequestResponseType, CancelRequestPropsType>({
      query: ({ myEmployeeId, ...body }) => ({
        url: createKedoUrl(`/me/cancel-request?myEmployeeId=${myEmployeeId}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: (_a, _b, { taskId }) => [
        { type: SERVICE_TAGS.GET_KEDO_TASKS },
        { type: SERVICE_TAGS.GET_KEDO_TASK_BY_ID, id: taskId },
      ],
    }),
    getSocialTasksWithCounters: builder.query<
      GetSocialTasksWithCountersResponseType,
      GetSocialTasksWithCountersPropsType
    >({
      query: (myEmployeeId) => ({
        url: createKedoUrl(`/me/social-task?myEmployeeId=${myEmployeeId}`),
      }),
    }),
    getAssignedToMeStatements: builder.query<
      GetAssignedToMeStatementsResponseType,
      GetAssignedToMeStatementsPropsType
    >({
      query: (params) => ({
        url: createKedoUrl(`/me/task-assignment${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_ASSIGNED_TASKS],
    }),
    getAssignedToMeStatementById: builder.query<
      GetAssignedToMeStatementByIdResponseType,
      GetAssignedToMeStatementByIdPropsType
    >({
      query: ({ taskId, ...params }) => ({
        url: createKedoUrl(`/me/task-assignment/${taskId}${prepareQueryParams(params)}`),
      }),
      providesTags: [SERVICE_TAGS.GET_ASSIGNED_TASK_BY_ID],
    }),
    updateAssignedToMeStatementById: builder.mutation<
      UpdateAssignedToMeStatementByIdResponseType,
      UpdateAssignedToMeStatementByIdPropsType
    >({
      query: ({ taskId, myEmployeeId, ...body }) => ({
        url: createKedoUrl(`/me/task-assignment/${taskId}?myEmployeeId=${myEmployeeId}`),
        method: 'POST',
        body,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_ASSIGNED_TASK_BY_ID, SERVICE_TAGS.GET_ASSIGNED_TASKS],
    }),
    getKedoStatuses: builder.query<GetKedoStatusesResponseType, GetKedoStatusesPropsType>({
      query: (params) => ({
        url: createKedoUrl(`/status${prepareQueryParams(params)}`),
      }),
      transformResponse: (statuses: GetKedoStatusesResponseType) => {
        const kedoStatusesMap = new Map();
        statuses.forEach((status) => {
          if (kedoStatusesMap.has(status.name)) {
            const existedStatus = kedoStatusesMap.get(status.name);

            status.code = `${existedStatus.code}&${status.code}`;
            kedoStatusesMap.set(status.name, status);
          } else {
            kedoStatusesMap.set(status.name, status);
          }
        });
        return Array.from(kedoStatusesMap.values());
      },
    }),
  }),
});

export const {
  useUpdateAssignedToMeStatementByIdMutation,
  useGetAssignedToMeStatementByIdQuery,
  useGetKedoTaskByIdQuery,
  useLazyGetAssignedToMeStatementsQuery,
  useGetSocialTasksWithCountersQuery,
  useCreateKedoTaskMutation,
  useLazyGetKedoStatementByIdQuery,
  useGetKedoTasksQuery,
  useGetKedoTasksDraftsQuery,
  useLazyGetKedoTasksQuery,
  useLazyGetKedoTasksDraftsQuery,
  useGetKedoStatementsQuery,
  useLazyGetKedoStatementsQuery,
  useGetKedoCategoriesQuery,
  useLazyRequestPayslipQuery,
  usePrepareKedoTaskMutation,
  useSaveDraftKedoTaskMutation,
  useSendKedoTaskMutation,
  useGetKedoCategoryByIdQuery,
  useGetKedoStatusesQuery,
  useGetKedoTaskLeadersQuery,
  useGetKedoStatementByCodeQuery,
  useCancelRequestMutation,
  useCancelKedoTaskMutation,
} = kedoApiService;
