import React, { MouseEvent, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import {
  DOCUMENTS_INQUIRIES_DETAILS_ROUTE,
  DOCUMENTS_INQUIRIES_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_DETAILS_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_ROUTE,
  DOCUMENTS_STATEMENTS_DETAILS_ROUTE,
  DOCUMENTS_STATEMENTS_ROUTE,
  getDocumentsInquiriesDetailsAttributesRoute,
  getDocumentsInquiriesLeadersRoute,
  getDocumentsSocialPolicyDetailsAttributesRoute,
  getDocumentsSocialPolicyLeadersRoute,
  getDocumentsStatementsDetailsAttributesRoute,
  getDocumentsStatementsLeadersRoute,
} from 'routes/documents/list';
import { t } from 'tools/i18n';
import { getCreatedDate } from 'utils/article.utils';
import useDownloadKedoFile from 'utils/useDownloadKedoFile.hook';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import initialApiService from 'services/initialApiService';
import { useCancelKedoTaskMutation } from 'services/kedo/kedoApiService';
import { useAppDispatch } from 'store';

import { Button, Flex, Form, Icon, Steps, Tag, Typography, handleRequest } from 'gazprom-ui-lib';

import KedoFile from 'components/kedo-file';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithPullToRefresh from 'containers/wrappers/with-pull-to-refresh';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { KedoTaskFile, TASK_KEDO_STATES } from 'types/documents.types';

import s from './DocumentsRequestsDetails.module.scss';
import CancelConfirmModal from './containers/cancel-confirm-modal';
import CancelModal from './containers/cancel-modal';
import CancelledModal from './containers/cancelled-modal';
import CreatedModal from './containers/created-modal';
import {
  ModalTypes,
  getFinalFiles,
  getFixRequestErrorRoute,
} from './documentsRequestsDetails.utils';
import useGetDescription from './useGetDescription.hook';
import useTasks from './useTask.hook';

const DocumentsRequestsDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const modalRef = useRef<boolean>(true);

  const [selectedEmployee] = useFullSelectedEmployee();

  const [form] = Form.useForm();
  const comment = Form.useWatch('comment', form);

  const isSocialPolicyRoute = !!useMatch(DOCUMENTS_SOCIAL_POLICY_DETAILS_ROUTE);
  const isStatementRoute = !!useMatch(DOCUMENTS_STATEMENTS_DETAILS_ROUTE);
  const isInquiriesRoute = !!useMatch(DOCUMENTS_INQUIRIES_DETAILS_ROUTE);

  const [modalType, setModalType] = useState<ModalTypes>('');

  const { data: task, isLoading: taskIsLoading, refetch: refetchTask } = useTasks();

  const [cancelKedoTask, { isLoading: isTaskCancelling }] = useCancelKedoTaskMutation();

  const canCancelStatement = isStatementRoute && !!task?.canCancelStatement;

  let headerLabel = 'documents_requests_view_title';
  let to = getDocumentsInquiriesDetailsAttributesRoute(task?.id ?? '');
  let toLeaders = getDocumentsInquiriesLeadersRoute(task?.id ?? '');
  let goBackLink = DOCUMENTS_INQUIRIES_ROUTE;
  const isTaskStatusError = task?.currentKedoStatus.state === TASK_KEDO_STATES.ERROR;

  if (isStatementRoute) {
    to = getDocumentsStatementsDetailsAttributesRoute(task?.id ?? '');
    toLeaders = getDocumentsStatementsLeadersRoute(task?.id ?? '');
    headerLabel = 'documents_statement_view_title';
    goBackLink = DOCUMENTS_STATEMENTS_ROUTE;
  } else if (isSocialPolicyRoute) {
    to = getDocumentsSocialPolicyDetailsAttributesRoute(task?.id ?? '');
    toLeaders = getDocumentsSocialPolicyLeadersRoute(task?.id ?? '');
    goBackLink = DOCUMENTS_SOCIAL_POLICY_ROUTE;
  }

  const { description, tagConfig, isError } = useGetDescription({ task });

  const handleModalClose = () => {
    setModalType('');
  };

  useEffect(() => {
    if (location.state?.modalType && modalRef.current) {
      setModalType(location.state.modalType);

      const invalidateTags = location.state?.invalidateTags;
      if (Array.isArray(invalidateTags)) {
        dispatch(initialApiService?.util?.invalidateTags(invalidateTags));
      }
      modalRef.current = false;
      navigate(location.pathname, { replace: true });
    }
  }, [
    dispatch,
    location.pathname,
    location.state?.invalidateTags,
    location.state?.modalType,
    navigate,
  ]);

  const { handleDownload, loadingKey } = useDownloadKedoFile();

  const renderFile = (file: KedoTaskFile) => {
    const { fileKey, fileName } = file;
    const archiveFileKey = fileKey + 'archive';

    return (
      <>
        <KedoFile
          file={file}
          key={fileKey}
          onClick={handleDownload({ fileKey, taskId: task!.id })}
          loading={loadingKey === fileKey}
        />
        <KedoFile
          file={file}
          key={archiveFileKey}
          onClick={handleDownload({ fileKey, taskId: task!.id, isArchive: true })}
          loading={loadingKey === archiveFileKey}
          fileName={t('documents_download_archive', { fileName })}
        />
      </>
    );
  };

  const headerChildren = (
    <>
      <Header.GoBack to={goBackLink} useDefaultGoBack />
      <Header.Label label={t(headerLabel)} />
    </>
  );

  const handleCancelStatement = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setModalType('cancel');
  };

  const handleConfirmCancelStatement = () => {
    setModalType('cancelConfirm');
  };

  const handleCancelStatementWithComment = () => {
    const onSuccess = () => {
      setModalType('cancelled');
    };

    const isCancelAvailable = !!task?.id && canCancelStatement && !!selectedEmployee?.id;

    if (isCancelAvailable) {
      cancelKedoTask({
        id: task.id,
        myEmployeeId: selectedEmployee.id,
        cancelComment: comment ?? '',
      }).then(handleRequest({ onSuccess }));
    }
  };

  const handleFixErrors = () => {
    if (task) {
      const route = getFixRequestErrorRoute(task, isSocialPolicyRoute, isStatementRoute);

      navigate(route, { state: { fromId: task.id } });
    }
  };

  const filesToShow = task?.files?.filter(getFinalFiles);

  const canFixErrors = !isInquiriesRoute && isError;

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren} showNavbar={!canFixErrors}>
        <WithPullToRefresh onRefresh={refetchTask}>
          <WithBackground>
            <Card.Navigate to={to}>
              <WithLoader isLoading={taskIsLoading}>
                <div className={s.navigate}>
                  <div className={s.inner}>
                    <Typography.Text size="12" type="secondary">
                      {getCreatedDate(task?.updatedAt)}
                    </Typography.Text>
                    <Typography.Title level={4}>{task?.statement?.name}</Typography.Title>
                    {tagConfig && (
                      <Tag className={cn(s.tag, s[tagConfig.type])} type={tagConfig.type}>
                        <Icon name={tagConfig.icon} /> <span>{tagConfig.children}</span>
                      </Tag>
                    )}
                    {description}
                    {!!filesToShow?.length && (
                      <div className={s.files}>{filesToShow?.map(renderFile)}</div>
                    )}
                  </div>
                  <Icon name="forward" />
                </div>
                {canCancelStatement && (
                  <Button
                    size="large"
                    leftIcon="return"
                    ghost
                    onClick={handleCancelStatement}
                    fullWidth>
                    {t('documents_statement_cancel')}
                  </Button>
                )}
              </WithLoader>
            </Card.Navigate>
            {isTaskStatusError && (
              <Card className={s.comment}>
                <Icon name="info" />
                <Typography.Text size="14" type="danger">
                  {task.currentKedoStatus.comment}
                </Typography.Text>
              </Card>
            )}
            {isStatementRoute && (
              <Card.Navigate to={toLeaders}>
                <Flex justify="space-between" align="center" fullWidth>
                  <Flex vertical align="flex-start" gap="4">
                    <Typography.Title level={4}>{t('documents_leaders')}</Typography.Title>
                    <Typography.Text type="secondary" size="14">
                      {t('documents_leaders_list')}
                    </Typography.Text>
                  </Flex>
                  <Flex align="center" justify="center" className={s.icon}>
                    <Icon size={32} name="groupsFilled" />
                  </Flex>
                </Flex>
              </Card.Navigate>
            )}
            <Card>
              <WithLoader isLoading={taskIsLoading}>
                <Typography.Title level={5}>{t('documents_coordination_route')}</Typography.Title>
                <Steps className={s.steps} items={task?.history ?? []} />
              </WithLoader>
            </Card>

            {canFixErrors && (
              <>
                <Card.Info className={s.errorCard} type="error">
                  {description}
                </Card.Info>
                <Card.Fixed>
                  <Button
                    onClick={handleFixErrors}
                    fullWidth
                    rightIcon="arrowRight"
                    size="large"
                    type="primary">
                    {t('common_fix_errors')}
                  </Button>
                </Card.Fixed>
              </>
            )}

            <CreatedModal isOpened={modalType === 'created'} onCancel={handleModalClose} />
            <CancelModal
              isOpened={modalType === 'cancel'}
              onCancel={handleModalClose}
              onConfirm={handleConfirmCancelStatement}
            />
            <CancelConfirmModal
              isOpened={modalType === 'cancelConfirm'}
              onCancel={handleModalClose}
              isLoading={isTaskCancelling}
              onSubmit={handleCancelStatementWithComment}
              form={form}
            />
            <CancelledModal isOpened={modalType === 'cancelled'} onCancel={handleModalClose} />
          </WithBackground>
        </WithPullToRefresh>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsRequestsDetails;
