import { Dayjs } from 'dayjs';
import { useParams } from 'react-router-dom';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useUpdateBusinessTripMutation } from 'services/me/work-tour/workTourApiService';
import { UpdateBusinessTripsPropsType } from 'services/me/work-tour/workTourApiService.types';

import { handleRequest } from 'gazprom-ui-lib';

import { BusinessTripFormProps } from 'containers/business-trip-form/businessTripForm';
import {
  FORM_ENUM,
  FormEditResponse,
  formatFormDate,
} from 'containers/business-trip-form/businessTripForm.utils';

import useHandleCreateEdit from '../../useHandleCreateEdit.hook';

interface UseHandleSubmitProps {
  handleModalOpenClose: () => void;
  from?: Dayjs;
}

const useHandleSubmit = (props: UseHandleSubmitProps) => {
  const { handleModalOpenClose, from } = props;
  const { id } = useParams<{ id: string }>();

  const [selectedEmployee] = useFullSelectedEmployee();

  const [prepareUpdatedBusinessTrip, { isLoading: prepareTaskIsLoading }] =
    useUpdateBusinessTripMutation();
  const { isLoading, handleContinueBusinessTripCreateEdit } = useHandleCreateEdit({
    onSuccess: handleModalOpenClose,
  });
  const handleSubmit: BusinessTripFormProps['handleSubmit'] = (initialFormResponse) => {
    const formResponse = initialFormResponse as FormEditResponse;
    const isCreateAvailable = selectedEmployee && id && from;
    if (isCreateAvailable) {
      const prepareTaskRequestBody: UpdateBusinessTripsPropsType = {
        myEmployeeId: selectedEmployee?.id,
        id,
        [FORM_ENUM.TO]: formatFormDate(formResponse[FORM_ENUM.TO]),
        [FORM_ENUM.DESTINATION]: formResponse[FORM_ENUM.DESTINATION],
        [FORM_ENUM.PURPOSE_EDIT]: formResponse[FORM_ENUM.PURPOSE_EDIT],
      };

      if (formResponse[FORM_ENUM.FROM]) {
        prepareTaskRequestBody[FORM_ENUM.FROM] = formatFormDate(formResponse[FORM_ENUM.FROM]);
      }

      if (formResponse[FORM_ENUM.PURPOSE_EDIT_SPECIFY]) {
        prepareTaskRequestBody.comment = formResponse[FORM_ENUM.PURPOSE_EDIT_SPECIFY];
      }

      if (formResponse[FORM_ENUM.DAY_OFF_FROM]) {
        prepareTaskRequestBody.dateFromHoliday = formatFormDate(
          formResponse[FORM_ENUM.DAY_OFF_FROM],
        );
      }

      if (formResponse[FORM_ENUM.DAY_OFF_TO]) {
        prepareTaskRequestBody.dateToHoliday = formatFormDate(formResponse[FORM_ENUM.DAY_OFF_TO]);
      }

      prepareUpdatedBusinessTrip(prepareTaskRequestBody).then(
        handleRequest({
          onSuccess: handleContinueBusinessTripCreateEdit,
        }),
      );
    }
  };

  return {
    handleSubmit,
    isLoading: prepareTaskIsLoading || isLoading,
  };
};

export default useHandleSubmit;
