import { useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';

import { Form, TextArea } from 'gazprom-ui-lib';

import TextAreaModal from 'containers/textarea-modal';

import { FORM_ENUM } from '../../businessTripForm.utils';

const Destination = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const form = Form.useFormInstance();
  const textValue = Form.useWatch(FORM_ENUM.DESTINATION);

  const onSubmitHandler = (value: Record<string, string>) => {
    const fieldValue = value['destination'];
    setIsModalVisible(!isModalVisible);
    form.setFieldValue(FORM_ENUM.DESTINATION, fieldValue);
  };

  return (
    <>
      <TextAreaModal
        name="destination"
        modalHeaderTitle={t('documents_add_destination')}
        title={t('common_destination')}
        maxLength={250}
        defaultValue={textValue}
        isModalOpen={isModalVisible}
        onSubmitHandler={onSubmitHandler}
        setIsModalOpen={() => setIsModalVisible(false)}
      />
      <Form.Item
        name={FORM_ENUM.DESTINATION}
        rules={[formRules.required]}
        label={t('common_destination')}>
        <TextArea
          onClick={() => {
            setIsModalVisible(true);
          }}
          rows={3}
          placeholder={t('common_enter_text')}
          maxLength={250}
          onFocus={(e) => e.target.blur()}
        />
      </Form.Item>
    </>
  );
};

export default Destination;
