import React, { useState } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';

import { useGetSettlementByIdQuery } from 'services/guide/guideApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import UniversalLoader from 'components/universal-loader';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import GuideSelected from '../guide-selected';
import s from './GuideMap.module.scss';

const GuideMap = () => {
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(true);
  const [selectedEmployee] = useFullSelectedEmployee();
  const { data, isLoading } = useGetSettlementByIdQuery({
    id: selectedEmployee?.currentSettlement.id!,
  });

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={() => navigate(-1)} />
      <Header.Label label={t('guide_selected_map_title')} />
    </>
  );

  const handleImageLoad = () => setImageLoading(false);

  const handleImageError = () => setImageLoading(false);

  const { handleOpenFile, loadingKey } = useOpenFileByUrl();
  const fileKey = data?.fileMap.link;
  const downloadFile = () =>
    handleOpenFile({ url: createMediaDownloadUrl(fileKey!), fileKey, withAuth: true });

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <GuideSelected text={data?.name ?? ''} />
          <WithLoader isLoading={isLoading}>
            <Card withPadding className={s.container}>
              <div className={s.headContainer}>
                <Typography.Title level={4}>{t('guide_selected_map_title')}</Typography.Title>
                <button className={s.download} onClick={downloadFile} disabled={!!loadingKey}>
                  <WithLoader isLoading={!!loadingKey} loader={<UniversalLoader size="20" />}>
                    <Icon name="download" />
                  </WithLoader>
                </button>
              </div>
              {data?.fileMap?.link && (
                <img
                  src={createMediaDownloadUrl(data?.fileMap?.link)}
                  alt={data?.fileMap?.name}
                  onLoad={handleImageLoad}
                  onError={handleImageError}
                  className={cn(s.image, imageLoading ? s.imageLoading : '')}
                />
              )}
              {imageLoading && (
                <div className={s.loaderContainer}>
                  <UniversalLoader />
                </div>
              )}
            </Card>
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default GuideMap;
