import { createContext } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import { BusinessTripFormProps } from './businessTripForm';

export enum FORM_ENUM {
  FROM = 'dateFrom',
  FROM_VISIBLE = 'dateFrom_VISIBLE',
  TO = 'dateTo',
  TO_VISIBLE = 'dateTo_VISIBLE',
  DESTINATION = 'destination',
  PURPOSE = 'target',
  PURPOSE_SPECIFY_SHOW = 'PURPOSE_SPECIFY_SHOW',
  PURPOSE_SPECIFY = 'targetComment',
  PURPOSE_EDIT = 'reason',
  PURPOSE_EDIT_SPECIFY = 'comment',
  PURPOSE_CANCEL = 'prichinaOtmenyKomandirovki',
  PURPOSE_CANCEL_SPECIFY = 'drugayaPrichinaOtmenyKomandirovki',
  SOURCE = 'sourceFinancing',
  SOURCE_TEXTAREA = 'SOURCE_TEXTAREA',
  DAY_OFF_FROM = 'dateFromHoliday',
  DAY_OFF_FROM_VISIBLE = 'dateFromHoliday_VISIBLE',
  DAY_OFF_TO = 'dateToHoliday',
  DAY_OFF_TO_VISIBLE = 'dateToHoliday_VISIBLE',
  PURPOSE_TEXTAREA = 'PURPOSE_TEXTAREA',
  APPROVER = 'firstLeader',
  CONFIRMER = 'approvedLeader',
}

export type FormCreateResponse = {
  [FORM_ENUM.FROM]: Dayjs;
  [FORM_ENUM.TO]: Dayjs;
  [FORM_ENUM.DESTINATION]: string;
  [FORM_ENUM.PURPOSE]: string;
  [FORM_ENUM.PURPOSE_SPECIFY]?: string;
  [FORM_ENUM.SOURCE]: string;
  [FORM_ENUM.APPROVER]: string;
  [FORM_ENUM.CONFIRMER]: string;
  [FORM_ENUM.DAY_OFF_FROM]?: Dayjs;
  [FORM_ENUM.DAY_OFF_TO]?: Dayjs;
};

export type FormEditResponse = {
  [FORM_ENUM.PURPOSE_EDIT]: string;
  [FORM_ENUM.PURPOSE_EDIT_SPECIFY]?: string;
  [FORM_ENUM.FROM]?: Dayjs;
  [FORM_ENUM.TO]: Dayjs;
  [FORM_ENUM.DESTINATION]: string;
  [FORM_ENUM.DAY_OFF_FROM]?: Dayjs;
  [FORM_ENUM.DAY_OFF_TO]?: Dayjs;
};

export type FormCancelResponse = {
  [FORM_ENUM.PURPOSE_CANCEL]: string;
  [FORM_ENUM.PURPOSE_CANCEL_SPECIFY]?: string;
};

export const formatDate = (formValue?: Dayjs) =>
  formValue ? formValue.format('DD.MM.YYYY') : undefined;

export const formatFormDate = (date: Dayjs) => dayjs(date).format('YYYY-MM-DD');
export const PURPOSE_OTHER = 'OTHER';
export type BusinessTripContextType = {
  type: BusinessTripFormProps['type'];
};
export const BusinessTripContext = createContext<BusinessTripContextType>({
  type: 'create',
});
