import React, { FC, useEffect, useState } from 'react';

import { AvailableResult, BiometryType } from 'capacitor-native-biometric';
import { t } from 'tools/i18n';
import { useAudit } from 'utils/useAudit.hook';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { Icon, Typography } from 'gazprom-ui-lib';

import MobileNumberKeyboard from 'components/mobile-number-keyboard';
import Passcode from 'components/passcode';
import WithGzpTopLogo from 'containers/wrappers/with-gzp-top-logo';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import AUDIT_EVENTS from 'constants/auditEvents';
import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import IdentifierAddFaceid from '../identifier-add-faceid';
import IdentifierAddTouchid from '../identifier-add-touchid';
import s from './IdentifierAddPasscode.module.scss';

interface IdentifierAddPasscodeProps {
  biometricStatus?: AvailableResult;
}

const IdentifierAddPasscode: FC<IdentifierAddPasscodeProps> = (props) => {
  const { biometricStatus } = props;

  const [, setLocalPassIsInstalled] = useSecureStorage(
    SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_INSTALLED_PASS,
    '',
  );

  const { auditSuccess } = useAudit();

  const [pass, setPass] = useState<string>('');
  const [passConfirm, setPassConfirm] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [passIsInstalled, setPassIsInstalled] = useState<boolean>(false);

  const passesAreSet = pass.length === 4 && passConfirm.length === 4;
  const isInitialPassSet = pass.length === 4;
  const passwordsAreTheSame = pass === passConfirm;

  useEffect(() => {
    if (passesAreSet) {
      if (passwordsAreTheSame) {
        auditSuccess(AUDIT_EVENTS.SETUP.PASSCODE);
        setIsSuccess(true);
      } else {
        setError(t('identifier_passcodes_mismatch'));
      }
    }
  }, [auditSuccess, passesAreSet, passwordsAreTheSame, setPassIsInstalled]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        if (biometricStatus?.isAvailable) {
          setPassIsInstalled(true);
        } else {
          setLocalPassIsInstalled(pass);
        }
      }, 2000);
    }
    if (error) {
      setTimeout(() => {
        setPass('');
        setPassConfirm('');
        setError('');
      }, 1000);
    }
  }, [error, setPassIsInstalled, isSuccess, biometricStatus, setLocalPassIsInstalled, pass]);

  const handleElementClick = (e?: string) => {
    const setter = isInitialPassSet ? setPassConfirm : setPass;
    setter((prevState) => {
      const shouldReturnPrevState = prevState.length === 4 || !e;

      if (shouldReturnPrevState) {
        return prevState;
      }
      setError('');
      setIsSuccess(false);

      return `${prevState}${e}`;
    });
  };

  const handleRemoveElement = () => {
    const setter = isInitialPassSet ? setPassConfirm : setPass;

    setError('');
    setIsSuccess(false);

    setter((prevState) => prevState.slice(0, prevState.length - 1));
  };

  const specialRightElement = {
    label: <Icon name="backspace" className={s.backspace} />,
    onClick: handleRemoveElement,
  };

  const getPassTitle = () => {
    if (pass && passConfirm && passwordsAreTheSame) {
      return 'identifier_passcode_set';
    }

    return isInitialPassSet ? 'identifier_confirm_passcode' : 'identifier_set_passcode';
  };

  const getSelectedClassName = () => {
    if (error) {
      return s.passwordsAreWrong;
    }

    if (isSuccess) {
      return s.passwordsAreMatched;
    }
  };
  const showSpecialElement = isInitialPassSet ? !!passConfirm : !!pass;

  let content = (
    <>
      <div className={s.logoWithCodeWrapper}>
        <div className={s.codeWithLabel}>
          <Typography.Title level={3}>{t(getPassTitle())}</Typography.Title>
          <Passcode
            selectedCount={isInitialPassSet ? passConfirm.length : pass.length}
            selectedClassName={getSelectedClassName()}
          />
          {!!error && (
            <Typography.Text type="danger" size="16">
              {error}
            </Typography.Text>
          )}
        </div>
      </div>
      <div className={s.keyboard}>
        <MobileNumberKeyboard
          disabled={isSuccess}
          specialRightElement={showSpecialElement ? specialRightElement : undefined}
          onElementClick={handleElementClick}
        />
      </div>
    </>
  );

  const isTouch =
    biometricStatus?.biometryType === BiometryType.TOUCH_ID ||
    biometricStatus?.biometryType === BiometryType.FINGERPRINT ||
    biometricStatus?.biometryType === BiometryType.MULTIPLE;

  if (passIsInstalled) {
    content = isTouch ? <IdentifierAddTouchid pass={pass} /> : <IdentifierAddFaceid pass={pass} />;
  }

  return (
    <WithStatusBar>
      <WithGzpTopLogo className={s.topLogoContainer}>{content}</WithGzpTopLogo>
    </WithStatusBar>
  );
};

export default IdentifierAddPasscode;
