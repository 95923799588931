import { FC } from 'react';

import { Link } from 'react-router-dom';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import s from './CardsSlider.module.scss';
import { CardsSliderType } from './cardsSlider.utils';

interface CardsSliderProps {
  cards: CardsSliderType[];
}

const CardsSlider: FC<CardsSliderProps> = (props) => {
  const { cards } = props;

  const renderInnerLinks = (link: CardsSliderType) => {
    const { icon, to, title, description } = link;
    return (
      <Link to={to} className={s.innerLinksCard} key={to}>
        <Flex vertical gap="4" fullHeight>
          <Icon name={icon} />
          <Flex vertical>
            <Typography.Text size="14" weight="500">
              {title}
            </Typography.Text>
            <Typography.Text size="12" type="secondary">
              {description}
            </Typography.Text>
          </Flex>
        </Flex>
      </Link>
    );
  };
  return (
    <Flex align="center" className={s.wrapper}>
      <Flex gap="8" align="center" className={s.innerLinksWrapper}>
        {cards.map(renderInnerLinks)}
      </Flex>
    </Flex>
  );
};

export default CardsSlider;
