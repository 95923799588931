import { TOP_MANAGEMENT_STAGE_ENUM } from 'types/topManagement.types';

export const DOCUMENTS_ROUTE = '/documents';
export const DOCUMENTS_CERTIFICATE_ROUTE = `${DOCUMENTS_ROUTE}/certificate`;
export const DOCUMENTS_CREATE_CERTIFICATES_ROUTE = `${DOCUMENTS_CERTIFICATE_ROUTE}/create`;
export const DOCUMENTS_SIGN_LIST_ROUTE = `${DOCUMENTS_ROUTE}/sign-list`;
export const DOCUMENTS_SIGN_CODE_ROUTE = `${DOCUMENTS_ROUTE}/sign-code`;
export const DOCUMENTS_BUSINESS_TRIP_ROUTE = `${DOCUMENTS_ROUTE}/business-trip`;

export const DOCUMENTS_BUSINESS_TRIP_CREATE_ROUTE = `${DOCUMENTS_BUSINESS_TRIP_ROUTE}/create`;
export const DOCUMENTS_BUSINESS_TRIP_DETAILS_ROUTE = `${DOCUMENTS_BUSINESS_TRIP_ROUTE}/:id`;
export const DOCUMENTS_BUSINESS_TRIP_APPROVERS_LIST_ROUTE = `${DOCUMENTS_BUSINESS_TRIP_DETAILS_ROUTE}/approvers`;
export const DOCUMENTS_BUSINESS_TRIP_DETAILS_EDIT_ROUTE = `${DOCUMENTS_BUSINESS_TRIP_DETAILS_ROUTE}/edit`;
export const DOCUMENTS_BUSINESS_TRIP_DETAILS_CANCEL_ROUTE = `${DOCUMENTS_BUSINESS_TRIP_DETAILS_ROUTE}/cancel`;
export const getDocumentsBusinessTripDetailsRoute = (id: string) =>
  `${DOCUMENTS_BUSINESS_TRIP_ROUTE}/${id}`;
export const getDocumentsBusinessTripEditRoute = (id: string) =>
  `${getDocumentsBusinessTripDetailsRoute(id)}/edit`;
export const getDocumentsBusinessTripCancelRoute = (id: string) =>
  `${getDocumentsBusinessTripDetailsRoute(id)}/cancel`;
export const getDocumentsBusinessTripApproversListRoute = (id: string) =>
  `${getDocumentsBusinessTripDetailsRoute(id)}/approvers`;

export const DOCUMENTS_SOCIAL_POLICY_ROUTE = `${DOCUMENTS_ROUTE}/social-policy`;
export const DOCUMENTS_SOCIAL_POLICY_DETAILS_ROUTE = `${DOCUMENTS_SOCIAL_POLICY_ROUTE}/:taskId`;
export const DOCUMENTS_SOCIAL_POLICY_DETAILS_ATTRIBUTES_ROUTE = `${DOCUMENTS_SOCIAL_POLICY_DETAILS_ROUTE}/attributes`;
export const DOCUMENTS_SOCIAL_POLICY_LEADERS_ROUTE = `${DOCUMENTS_SOCIAL_POLICY_DETAILS_ROUTE}/leaders`;

export const DOCUMENTS_STATEMENTS_ROUTE = `${DOCUMENTS_ROUTE}/statements`;
export const DOCUMENTS_STATEMENTS_DETAILS_ROUTE = `${DOCUMENTS_STATEMENTS_ROUTE}/:taskId`;
export const DOCUMENTS_STATEMENTS_DETAILS_ATTRIBUTES_ROUTE = `${DOCUMENTS_STATEMENTS_DETAILS_ROUTE}/attributes`;
export const DOCUMENTS_STATEMENTS_LEADERS_ROUTE = `${DOCUMENTS_STATEMENTS_DETAILS_ROUTE}/leaders`;
export const DOCUMENTS_STATEMENT_REQUEST_ROUTE = `${DOCUMENTS_STATEMENTS_ROUTE}/request/:statementId`;

export const DOCUMENTS_INQUIRIES_ROUTE = `${DOCUMENTS_ROUTE}/inquiries`;
export const DOCUMENTS_INQUIRIES_DETAILS_ROUTE = `${DOCUMENTS_INQUIRIES_ROUTE}/:taskId`;
export const DOCUMENTS_INQUIRIES_DETAILS_ATTRIBUTES_ROUTE = `${DOCUMENTS_INQUIRIES_DETAILS_ROUTE}/attributes`;
export const DOCUMENTS_INQUIRIES_LEADERS_ROUTE = `${DOCUMENTS_INQUIRIES_DETAILS_ROUTE}/leaders`;
export const DOCUMENTS_INQUIRIES_REQUEST_ROUTE = `${DOCUMENTS_INQUIRIES_ROUTE}/request/:statementId`;
export const getDocumentsInquiriesDetailsRoute = (id: string) =>
  `${DOCUMENTS_INQUIRIES_ROUTE}/${id}`;
export const getDocumentsInquiriesDetailsAttributesRoute = (id: string) =>
  `${getDocumentsInquiriesDetailsRoute(id)}/attributes`;
export const getDocumentsInquiriesLeadersRoute = (id: string) =>
  `${getDocumentsInquiriesDetailsRoute(id)}/leaders`;

export const DOCUMENTS_TASKS_ROUTE = `${DOCUMENTS_ROUTE}/tasks`;
export const DOCUMENTS_TASKS_DETAILS_ROUTE = `${DOCUMENTS_TASKS_ROUTE}/:taskId`;
export const DOCUMENTS_TASKS_DETAILS_ATTRIBUTES_ROUTE = `${DOCUMENTS_TASKS_DETAILS_ROUTE}/attributes`;

export const DOCUMENTS_STATEMENT_CHOOSE_CATEGORY_ROUTE = `${DOCUMENTS_STATEMENTS_ROUTE}/choose`;
export const DOCUMENTS_INQUIRIES_CHOOSE_CATEGORY_ROUTE = `${DOCUMENTS_INQUIRIES_ROUTE}/choose`;

export const DOCUMENTS_STATEMENT_SEARCH_CATEGORY_ROUTE = `${DOCUMENTS_STATEMENTS_ROUTE}/search`;
export const DOCUMENTS_INQUIRIES_SEARCH_CATEGORY_ROUTE = `${DOCUMENTS_INQUIRIES_ROUTE}/search`;

export const DOCUMENTS_TOP_MANAGEMENT_BY_STAGE_ROUTE = `${DOCUMENTS_ROUTE}/top-management/:stage`;

export const getDocumentsTasksDetailsRoute = (id: string) => `${DOCUMENTS_TASKS_ROUTE}/${id}`;
export const getDocumentsTasksDetailsAttributesRoute = (id: string) =>
  `${getDocumentsTasksDetailsRoute(id)}/attributes`;

export const getDocumentsStatementsDetailsRoute = (id: string) =>
  `${DOCUMENTS_STATEMENTS_ROUTE}/${id}`;
export const getDocumentsStatementsDetailsAttributesRoute = (id: string) =>
  `${getDocumentsStatementsDetailsRoute(id)}/attributes`;
export const getDocumentsStatementsLeadersRoute = (id: string) =>
  `${getDocumentsStatementsDetailsRoute(id)}/leaders`;
export const getDocumentsSocialPolicyDetailsDetailsRoute = (id: string) =>
  `${DOCUMENTS_SOCIAL_POLICY_ROUTE}/${id}`;

export const getDocumentsSocialPolicyDetailsAttributesRoute = (id: string) =>
  `${getDocumentsSocialPolicyDetailsDetailsRoute(id)}/attributes`;
export const getDocumentsSocialPolicyLeadersRoute = (id: string) =>
  `${getDocumentsSocialPolicyDetailsDetailsRoute(id)}/leaders`;

export const getDocumentsStatementRequestRoute = (id: string) =>
  `${DOCUMENTS_STATEMENTS_ROUTE}/request/${id}`;
export const getDocumentsInquiriesRequestRoute = (id: string) =>
  `${DOCUMENTS_INQUIRIES_ROUTE}/request/${id}`;

export const getTopManagementByStageRoute = (stage: TOP_MANAGEMENT_STAGE_ENUM) =>
  `${DOCUMENTS_ROUTE}/top-management/${stage}`;
