import { Fragment, useEffect, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';

import {
  useGetSettlementByIdQuery,
  useGetSettlementInstructionsQuery,
} from 'services/guide/guideApiService';

import { Divider, Icon, Typography } from 'gazprom-ui-lib';

import TagButton from 'components/tag-button';
import UniversalLoader from 'components/universal-loader';
import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { GuideMemoCategoryType, GuideMemoInstructionType } from 'types/guide.types';

import GuideSelected from '../guide-selected';
import s from './GuideMemo.module.scss';
import GuideMemoStage from './guide-memo-stage';

const GuideMemo = () => {
  const navigate = useNavigate();

  const [activeCategory, setActiveCategory] = useState<GuideMemoCategoryType | null>(null);

  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: settlementData, isLoading: settlementDataIsLoading } = useGetSettlementByIdQuery({
    id: selectedEmployee?.currentSettlement.id!,
  });
  const { data: settlementInstructionsData, isLoading: settlementInstructionsDataIsLoading } =
    useGetSettlementInstructionsQuery({
      settlementId: selectedEmployee?.currentSettlement.id!,
    });

  const isLoading = settlementDataIsLoading || settlementInstructionsDataIsLoading;

  const selectedCategories = useMemo(() => {
    return settlementInstructionsData?.reduce((acc, cur) => {
      acc.push(cur.category);
      return acc;
    }, [] as GuideMemoCategoryType[]);
  }, [settlementInstructionsData]);

  const selectedInstructions = useMemo(() => {
    return settlementInstructionsData?.find(
      (instruction) => instruction.category.id === activeCategory?.id,
    )?.instructions;
  }, [activeCategory, settlementInstructionsData]);

  useEffect(() => {
    if (selectedCategories?.length) {
      setActiveCategory(selectedCategories[0]);
    }
  }, [selectedCategories]);

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={() => navigate(-1)} />
      <Header.Label label={t('guide_selected_memo_title')} />
    </>
  );

  const memoHasInstruction = !!settlementData?.fileInstruction?.link;
  const memoHasMap = !!settlementData?.fileMap?.link;
  const renderStage = (params: GuideMemoInstructionType, index: number) => {
    const { id, name, ...other } = params;

    return (
      <Fragment key={id}>
        <Divider />
        <div className={s.content}>
          <GuideMemoStage
            withMap={memoHasMap}
            withSteps={!!activeCategory?.withEtaps}
            stageNumber={index + 1}
            title={name}
            {...other}
          />
        </div>
      </Fragment>
    );
  };

  const renderTabs = (category: GuideMemoCategoryType) => {
    return (
      <TagButton
        isActive={activeCategory?.id === category.id}
        id={category.id}
        name={category.name}
        toggleIsActive={() => setActiveCategory(category)}
      />
    );
  };
  const { handleOpenFile, loadingKey } = useOpenFileByUrl();
  const link = settlementData?.fileInstruction?.link ?? '';
  const downloadFile = () => {
    if (memoHasInstruction) {
      handleOpenFile({ url: createMediaDownloadUrl(link), fileKey: link, withAuth: true });
    }
  };

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <GuideSelected text={selectedEmployee?.currentSettlement.name!} />
          <WithLoader isLoading={isLoading}>
            <Card withPadding={false}>
              <div className={s.headContainer}>
                <Typography.Title level={4}>{t('guide_selected_memo_title')}</Typography.Title>
                {memoHasInstruction && (
                  <button className={s.download} onClick={downloadFile} disabled={!!loadingKey}>
                    <WithLoader isLoading={!!loadingKey} loader={<UniversalLoader size="20" />}>
                      <Icon name="download" />
                    </WithLoader>
                  </button>
                )}
              </div>
              <div className={s.categories}>{selectedCategories?.map(renderTabs)}</div>
              {selectedInstructions?.map(renderStage)}
            </Card>
          </WithLoader>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default GuideMemo;
