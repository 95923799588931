import { useLocation, useNavigate } from 'react-router-dom';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { activeKedoTaskSelector } from 'selectors/kedoSlice.selector';
import initialApiService from 'services/initialApiService';
import { useSendKedoTaskMutation } from 'services/kedo/kedoApiService';
import { useSignConfirmMutation } from 'services/me/meApiService';
import { useSendBusinessTripMutation } from 'services/me/work-tour/workTourApiService';
import { useFinalizeRenewFilesMutation } from 'services/top-management/topManagementApiService';
import { setActiveKedoFormTask } from 'slices/kedo';
import { useAppDispatch, useAppSelector } from 'store';

import { handleRequest } from 'gazprom-ui-lib';

import { VERIFICATION_MODALS_ENUM } from 'containers/code-verification/code-verification.utils';

import { SERVICE_TAGS } from 'constants/serviceTags';
import { OPERATION_STATUS_ENUM } from 'types/documents.types';

const useHandleSubmit = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selectedEmployee] = useFullSelectedEmployee();

  const activeKedoTask = useAppSelector(activeKedoTaskSelector);

  const { state } = location;

  const [signConfirm, { isLoading: isSignConfirmLoading }] = useSignConfirmMutation();
  const [sendBusinessTrip, { isLoading: sendBusinessTripIsLoading }] =
    useSendBusinessTripMutation();
  const [sendTask, { isLoading: isSendLoading }] = useSendKedoTaskMutation();
  // TODO REMOVE
  const [finalizeFiles, { isLoading: isFinalizeFilesLoading }] = useFinalizeRenewFilesMutation();

  const isLoading =
    isSignConfirmLoading || isSendLoading || isFinalizeFilesLoading || sendBusinessTripIsLoading;

  const handleSubmit = async (smsCode: string): Promise<VERIFICATION_MODALS_ENUM> => {
    let statusCode = VERIFICATION_MODALS_ENUM.ERROR;

    const res = await signConfirm({
      confirmationCode: smsCode,
      operationId: state.operationId,
    });

    if ('data' in res) {
      dispatch(setActiveKedoFormTask(null));

      const operationStatus = res.data.operationStatus;

      if (operationStatus === OPERATION_STATUS_ENUM.WRONG_CODE) {
        statusCode = VERIFICATION_MODALS_ENUM.WRONG_CODE;
      } else if (
        [
          OPERATION_STATUS_ENUM.CANCELED_BY_USER,
          OPERATION_STATUS_ENUM.CONFIRMATION_FAILED,
          OPERATION_STATUS_ENUM.CRASHED,
        ].includes(operationStatus)
      ) {
        statusCode = VERIFICATION_MODALS_ENUM.ERROR;
      } else if (operationStatus === OPERATION_STATUS_ENUM.COMPLETED) {
        const onSuccess = () => {
          if (cameFrom) {
            return navigate(cameFrom, { state: location.state });
          }
          statusCode = VERIFICATION_MODALS_ENUM.COMPLETED;
        };

        const onError = () => {
          statusCode = VERIFICATION_MODALS_ENUM.ERROR;
        };

        const cameFrom = location.state?.cameFrom;
        if (selectedEmployee) {
          switch (state.type) {
            case 'finalize': {
              if (state.finalizeBody && activeKedoTask) {
                await finalizeFiles({
                  id: activeKedoTask.taskId,
                  myEmployeeId: selectedEmployee.id,
                  body: state.finalizeBody,
                }).then(
                  handleRequest({
                    onSuccess,
                    onError,
                  }),
                );
              }
              break;
            }
            case 'confirmAssignment': {
              // On task confirm we don't have activeKedoTask?.taskId and shouldn't call sendTask
              onSuccess();
              break;
            }
            case 'businessTrip': {
              await sendBusinessTrip({
                myEmployeeId: selectedEmployee.id,
                id: state.workTourId,
                files: res.data.files,
              }).then(
                handleRequest({
                  onSuccess: () => {
                    dispatch(
                      initialApiService?.util?.invalidateTags([SERVICE_TAGS.GET_BUSINESS_TRIPS]),
                    );
                    onSuccess();
                  },
                  onError,
                }),
              );
              break;
            }
            case 'v2WorkTourTypeSign':
            case 'v2kedoTaskTypeSign':
            case 'v2kedoDocumentTypeSign':
            case 'v2kedoReviewTypeSign': {
              dispatch(
                initialApiService?.util?.invalidateTags([
                  SERVICE_TAGS.GET_ASSIGNED_TASK_BY_ID,
                  SERVICE_TAGS.GET_ASSIGNED_TASKS,
                ]),
              );
              onSuccess();
              break;
            }
            default: {
              if (activeKedoTask?.taskId) {
                await sendTask({
                  selectedEmployee: selectedEmployee?.id!,
                  id: activeKedoTask?.taskId!,
                  body: {
                    files: res.data.files,
                  },
                }).then(
                  handleRequest({
                    onSuccess,
                    onError,
                  }),
                );
              }
            }
          }
        } else {
          navigate(cameFrom, { state: location.state });
        }
      } else if (operationStatus === OPERATION_STATUS_ENUM.EXCEEDED_ATTEMPTS_COUNT) {
        statusCode = VERIFICATION_MODALS_ENUM.EXCEEDED_ATTEMPTS;
      } else if (operationStatus === OPERATION_STATUS_ENUM.TIMEOUT) {
        statusCode = VERIFICATION_MODALS_ENUM.TIME_IS_UP;
      }
    }

    return statusCode;
  };

  return {
    handleSubmit,
    isLoading,
  };
};

export default useHandleSubmit;
