import React, { FC } from 'react';

import { t } from 'tools/i18n';

import { Flex, Typography } from 'gazprom-ui-lib';

import { BusinessTripType } from 'types/businessTrip.types';

interface AttributesProps {
  businessTrip?: BusinessTripType;
}

const Attributes: FC<AttributesProps> = (props) => {
  const { businessTrip } = props;

  const {
    destination,
    employee,
    duration,
    target,
    sourceFinancing,
    targetComment,
    reasonCancellation,
    reasonCancellationComment,
  } = businessTrip ?? {};

  return (
    <>
      <Flex vertical>
        <Typography.Text size="12" type="secondary">
          {t('common_destination')}
        </Typography.Text>
        <Typography.Title level={5}>{destination}</Typography.Title>
      </Flex>
      <Flex vertical>
        <Typography.Text size="12" type="secondary">
          {t('common_structural_subdivision')}
        </Typography.Text>
        <Typography.Title level={5}>{employee?.division?.name}</Typography.Title>
      </Flex>
      <Flex vertical>
        <Typography.Text size="12" type="secondary">
          {t('documents_business_trip_days_count')}
        </Typography.Text>
        <Typography.Title level={5}>{duration}</Typography.Title>
      </Flex>
      <Flex vertical>
        <Typography.Text size="12" type="secondary">
          {t('documents_business_trip_purpose')}
        </Typography.Text>
        <Typography.Title level={5}>{target?.name}</Typography.Title>
      </Flex>
      {targetComment && (
        <Flex vertical>
          <Typography.Text size="12" type="secondary">
            {t('documents_business_trip_purpose_clarification')}
          </Typography.Text>
          <Typography.Title level={5}>{targetComment}</Typography.Title>
        </Flex>
      )}
      <Flex vertical>
        <Typography.Text size="12" type="secondary">
          {t('common_source_of_financing')}
        </Typography.Text>
        <Typography.Title level={5}>{sourceFinancing}</Typography.Title>
      </Flex>
      {reasonCancellation && (
        <Flex vertical>
          <Typography.Text size="12" type="secondary">
            {t('common_decline_reason')}
          </Typography.Text>
          <Typography.Title level={5}>{reasonCancellation?.name}</Typography.Title>
        </Flex>
      )}
      {reasonCancellationComment && (
        <Flex vertical>
          <Typography.Text size="12" type="secondary">
            {t('documents_business_trip_cancel_clarification')}
          </Typography.Text>
          <Typography.Title level={5}>{reasonCancellationComment}</Typography.Title>
        </Flex>
      )}
    </>
  );
};

export default Attributes;
