import {
  GUIDE_IMPORTANT_CONTACTS_ROUTE,
  GUIDE_MAP_ROUTE,
  GUIDE_MEMO_ROUTE,
} from 'routes/guide/list';
import { t } from 'tools/i18n';

import { CardsSliderType } from 'components/cards-slider/cardsSlider.utils';

export const getCards = (withMap: boolean): CardsSliderType[] => {
  const map: CardsSliderType = {
    to: GUIDE_MAP_ROUTE,
    title: t('guide_selected_map'),
    description: t('guide_select_work_place_map'),
    icon: 'map',
  };

  const cards: CardsSliderType[] = [
    {
      to: GUIDE_MEMO_ROUTE,
      title: t('guide_selected_memo_title'),
      description: t('guide_selected_memo_subtitle'),
      icon: 'radar',
    },
    {
      to: GUIDE_IMPORTANT_CONTACTS_ROUTE,
      title: t('guide_selected_important_contacts_title'),
      description: t('guide_selected_important_contacts_subtitle'),
      icon: 'groupsFilled',
    },
  ];

  if (withMap) {
    cards.splice(1, 0, map);
  }

  return cards;
};
