import { Component, PropsWithChildren } from 'react';

import { HOME_ROUTE } from 'routes/home/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import Error from 'pages/error';

import { State } from './withErrorBoundary.utils';

class WithErrorBoundary extends Component<PropsWithChildren, State> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error) {
    console.error('Uncaught error:', error);
  }

  private handleReload = () => {
    this.setState({ hasError: false });
    window.location.href = HOME_ROUTE;
  };

  public render() {
    const { hasError } = this.state;
    console.log(hasError);

    if (hasError) {
      return (
        <Error
          initialActions={
            <Button
              onClick={this.handleReload}
              fullWidth
              size="large"
              loading={false}
              leftIcon="refresh"
              type="primary">
              {t('common_retry_again')}
            </Button>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default WithErrorBoundary;
