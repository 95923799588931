import React, { Dispatch, FC, SetStateAction, useState } from 'react';

import { t } from 'tools/i18n';

import { Flex, Form, Icon, Input, InputProps, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import { ModalType } from 'containers/business-trip-form/containers/approvers/approvers';
import Card from 'containers/card';
import Header from 'containers/header';
import PageModal from 'containers/page-modal';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';

import { LeaderType } from 'types/employee.types';

import { FORM_ENUM } from '../../../../businessTripForm.utils';
import s from './SelectWithSearch.module.scss';

interface SelectWithSearchProps {
  modalType: ModalType;
  setModalType: Dispatch<SetStateAction<ModalType>>;
  leadersData?: LeaderType[];
  leadersIsFetching: boolean;
}

const SelectWithSearch: FC<SelectWithSearchProps> = (props) => {
  const { modalType, setModalType, leadersData, leadersIsFetching } = props;

  const [searchValue, setSearchValue] = useState<string>('');

  const form = Form.useFormInstance();
  const handleGoBack = () => {
    setModalType(null);
  };

  const handleSetData = (id: string) => () => {
    if (modalType) {
      form.setFieldValue(modalType, id);
      handleGoBack();
    }
  };

  const handleInputChange: InputProps['onChange'] = (e) => {
    setSearchValue(e.target.value);
  };

  const renderOption = (leader: LeaderType) => {
    const { employeeId, person, position, division } = leader;
    return (
      <button
        className={s.option}
        type="button"
        onClick={handleSetData(employeeId!)}
        key={employeeId}>
        <Flex align="center" justify="space-between">
          <Bio showAvatar staffName={position.name} employerName={division.name} {...person} />
          <Icon name="forward" />
        </Flex>
      </button>
    );
  };
  const isApprover = modalType === FORM_ENUM.APPROVER;

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={handleGoBack} />
      <Header.Label
        label={t(isApprover ? 'common_select_approvers' : 'common_select_confirmators')}
      />
    </>
  );

  const filterOptions = (leadersData: LeaderType) =>
    JSON.stringify(leadersData).trim().toLowerCase().includes(searchValue.trim().toLowerCase());

  const optionsToShow = leadersData?.filter(filterOptions);

  return (
    <PageModal open={!!modalType}>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card withPadding={false}>
            <WithLoader isLoading={leadersIsFetching}>
              <Flex vertical gap="16">
                <Flex vertical className={s.padding} gap="16">
                  <Typography.Title level={4}>
                    {t(isApprover ? 'common_approval' : 'common_confirmation')}
                  </Typography.Title>
                  <Input
                    value={searchValue}
                    onChange={handleInputChange}
                    prefix={<Icon name="search" />}
                    size="large"
                    placeholder={t('common_fast_search')}
                  />
                </Flex>

                <Flex vertical>
                  {optionsToShow?.length ? (
                    <Flex vertical>{optionsToShow?.map(renderOption)}</Flex>
                  ) : (
                    <Flex align="center" justify="center" className={s.paddingBottom}>
                      <Typography.Text size="14" type="secondary">
                        {t('common_no_results_found')}
                      </Typography.Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </PageModal>
  );
};

export default SelectWithSearch;
