import React, { FC, TouchEvent, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useSubmitMoodMutation } from 'services/mood/moodApiService';

import { Flex, Icon, Typography, handleRequest } from 'gazprom-ui-lib';

import Card from 'containers/card';

import { MoodTypes } from 'types/mood.types';

import s from './HomeMood.module.scss';
import { MOOD_OPTIONS, MoodItemType } from './homeMood.utils';

interface HomeMoodProps {
  isModal?: boolean;
  handleClose?: () => void;
  handleSwipe?: () => void;
  handleMoodSubmit: () => void;
}

const HomeMood: FC<HomeMoodProps> = (props) => {
  const { isModal = false, handleClose, handleSwipe, handleMoodSubmit } = props;

  const [isClosing, setIsClosing] = useState<boolean>(false);
  const [isSwiped, setIsSwiped] = useState<boolean>(false);
  const [swipeDiff, setSwipeDiff] = useState<number>(0);

  const modalRef = useRef<HTMLDivElement>(null);
  const startX = useRef<null | number>(null);
  const swipeTimer = useRef<null | ReturnType<typeof setTimeout>>(null);

  useEffect(() => {
    return () => {
      if (swipeTimer.current) {
        clearTimeout(swipeTimer.current);
      }
    };
  }, []);

  const [selectedEmployee] = useFullSelectedEmployee();

  const [submitMood] = useSubmitMoodMutation();

  const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
    startX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
    if (!startX.current) return;

    const currentX = e.touches[0].clientX;
    const diff = currentX - startX.current;

    if (diff > 0) {
      setSwipeDiff(diff);
    }

    if (diff > 50) {
      setIsSwiped(true);
    }
  };

  const handleTouchEnd = () => {
    setSwipeDiff(0);

    if (handleSwipe && isSwiped) {
      swipeTimer.current = setTimeout(() => {
        handleSwipe();
        setIsSwiped(false);
      }, 300);

      startX.current = null;
    }
  };

  const handleSubmit = (moodType: MoodTypes) => {
    const onSuccess = () => {
      setIsClosing(true);

      setTimeout(() => {
        handleMoodSubmit();
        setIsClosing(false);
      }, 300);
    };

    submitMood({
      myEmployeeId: selectedEmployee!.id,
      moodType: moodType,
    }).then(
      handleRequest({
        onSuccess,
      }),
    );
  };

  const renderItem = (moodItem: MoodItemType) => {
    const handleClick = () => {
      if (!!submitMood && selectedEmployee?.id) {
        handleSubmit(moodItem.type);
      }
    };

    return (
      <Flex className={s.moodItem} onClick={handleClick} key={moodItem.type}>
        <img src={moodItem.src} alt={moodItem.type} />
      </Flex>
    );
  };

  const handleMoodClose = () => {
    if (handleClose) {
      setIsClosing(true);
      setTimeout(() => {
        handleClose();
        setIsClosing(false);
      }, 300);
    }
  };

  const homeMoodContent = (
    <Card className={cn(s.wrapper, isClosing && s.closing)} ref={modalRef}>
      <Flex fullWidth vertical align="center" className={s.content} gap="16">
        {!isModal && (
          <Flex className={s.close} onClick={handleMoodClose}>
            <Icon name="delete" />
          </Flex>
        )}
        <Typography.Text weight="500" size="16">
          {t('home_mood_rate')}
        </Typography.Text>
        <Flex gap="32">{MOOD_OPTIONS.map(renderItem)}</Flex>
      </Flex>
    </Card>
  );

  if (isModal) {
    return (
      <Flex
        style={{ left: isSwiped ? '50%' : swipeDiff + 8 }}
        className={cn(s.modal, isSwiped && s.modalSwiped)}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}>
        {homeMoodContent}
      </Flex>
    );
  }

  return homeMoodContent;
};

export default HomeMood;
