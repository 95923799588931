import React, { FC } from 'react';

import { createMediaDownloadUrl, createMediaUploadUrl } from 'utils/createMediaUrl.utils';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';

import { Flex } from 'gazprom-ui-lib';

import KedoFile from 'components/kedo-file';

import { BusinessTripType } from 'types/businessTrip.types';
import { FileType } from 'types/file.types';

interface FilesProps {
  files?: BusinessTripType['files'];
}

const Files: FC<FilesProps> = (props) => {
  const { files = [] } = props;

  const { handleOpenFile, loadingKey } = useOpenFileByUrl();

  const renderFile = (file: FileType) => {
    const { fileKey } = file;

    return (
      <KedoFile
        isAttributeFile={false}
        file={file}
        key={fileKey}
        onClick={() =>
          handleOpenFile({
            url: createMediaDownloadUrl(createMediaUploadUrl(fileKey)),
            withAuth: true,
            fileKey,
          })
        }
        loading={loadingKey === fileKey}
      />
    );
  };

  return <Flex vertical>{files.map(renderFile)}</Flex>;
};

export default Files;
