import React, { FC, Fragment, useContext } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';

import { useGetFeedbackCategoriesQuery } from 'services/feedback-category/feedbackCategoriesApiService';

import { Icon, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';

import s from '../ProfileFeedbackCreate.module.scss';
import {
  CONFIG_STATE,
  FeedbackCategoryType,
  FeedbackCreateContext,
} from '../profileFeedbackCreate.utils';

interface FeedbackCreateCategoryProps {
  nextStep?: CONFIG_STATE;
  showWrapper?: boolean;
}

const ProfileFeedbackCreateCategory: FC<FeedbackCreateCategoryProps> = (props) => {
  const { nextStep, showWrapper = true } = props;

  const { data: feedbackCategoriesData, isLoading: feedbackCategoriesIsLoading } =
    useGetFeedbackCategoriesQuery();

  const { setFeedbackCategory, setConfigStep, feedbackCategory } =
    useContext(FeedbackCreateContext);

  const renderFeedbackCategory = (category: FeedbackCategoryType, index: number) => {
    if (!category || !setFeedbackCategory || !setConfigStep) {
      return <Fragment key={`broken_category_${index}`}></Fragment>;
    }

    const handleCategoryClick = () => {
      setConfigStep((prevState) => ({ current: nextStep ?? prevState.previous! }));
      setFeedbackCategory(category);
    };
    return (
      <button
        onClick={handleCategoryClick}
        className={cn(s.defaultCard, s.padding)}
        key={category.id}>
        <Typography.Title level={5}>{category.name}</Typography.Title>
        <Icon name="forward" />
      </button>
    );
  };

  const categoriesToUse = feedbackCategory
    ? feedbackCategoriesData?.filter((category) => category.id !== feedbackCategory.id)
    : feedbackCategoriesData;

  const handleGoBack = () => {
    if (setConfigStep) {
      setConfigStep((prevState) => ({
        current: prevState.previous!,
      }));
    }
  };

  const header = (
    <>
      <Header.GoBackButton onClick={handleGoBack} />
      <Header.Label label={t('feedback_category')} />
    </>
  );

  const content = (
    <Card>
      <Typography.Title level={4} className={s.header}>
        {t('feedback_category')}
      </Typography.Title>
      <WithLoader isLoading={feedbackCategoriesIsLoading}>
        {categoriesToUse?.map(renderFeedbackCategory)}
      </WithLoader>
    </Card>
  );

  if (showWrapper) {
    return (
      <WithNavigation headerChildren={header}>
        <WithBackground>{content}</WithBackground>
      </WithNavigation>
    );
  }

  return content;
};

export default ProfileFeedbackCreateCategory;
