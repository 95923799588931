import { KedoTaskFile, KedoTaskFilledAttribute } from 'types/documents.types';

export const getFilledAttributesWithFiles = (
  filledAttributes?: KedoTaskFilledAttribute[],
  files?: KedoTaskFile[],
) => {
  const attributesWithFiles =
    filledAttributes?.reduce((acc: KedoTaskFilledAttribute[], attribute) => {
      const attributeFiles = files?.filter((file) => file.attributeId === attribute.attributeId);

      if (attributeFiles) {
        const attributeWithFile = { ...attribute, files: attributeFiles };
        acc.push(attributeWithFile);
      } else {
        acc.push(attribute);
      }

      return acc;
    }, []) ?? [];

  const isFileUnlinked = (file: KedoTaskFile) => {
    return !filledAttributes?.some((attribute) => attribute.attributeId === file.attributeId);
  };

  const unlinkedFiles = files?.filter(isFileUnlinked) ?? [];

  return [attributesWithFiles, unlinkedFiles];
};

export type ACTIVE_MODAL_TYPE = '' | 'cancellationComment';
