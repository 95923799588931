import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Icon } from 'gazprom-ui-lib';

import { KedoLeaderType } from 'types/employee.types';

export const getStatusTitle = (status: KedoLeaderType['status'], finishDate: string) => {
  if (status === 'WAITING' || !status || !finishDate) return '';

  const finishDateText = dayjs(finishDate).format('DD.MM.YYYY, HH:mm');

  if (status === 'APPROVED') {
    return `${t('common_approved')}: ${finishDateText}`;
  }

  if (status === 'REJECTED') {
    return `${t('documents_status_rejected_label')}: ${finishDateText}`;
  }
};

export const getAvatarStatusIcon = (status: KedoLeaderType['status']) => {
  if (status === 'APPROVED') {
    return <Icon name="ok" />;
  }

  if (status === 'REJECTED') {
    return <Icon name="error" />;
  }

  return <Icon name="time" />;
};
