import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import CardsTile from 'components/cards-tile';
import SupportCard from 'components/support-card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import Contract from './containers/contract';
import ProfileWithCompany from './containers/profile-with-company';
import { CALENDAR_CARDS, PROFILE_CARDS } from './profile.utils';

const Profile = () => {
  const [selectedEmployee] = useFullSelectedEmployee();

  const headerChildren = (
    <>
      <Header.Logo />
      <Header.Notification />
    </>
  );
  const { needContractRenewal, endWorkDate } = selectedEmployee || {};

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <ProfileWithCompany />
          {needContractRenewal && <Contract date={endWorkDate} />}
          <CardsTile title={t('profile_data')} cards={PROFILE_CARDS} />
          <CardsTile title={t('common_calendar')} cards={CALENDAR_CARDS} />
          <SupportCard />
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};
export default Profile;
