import { useCallback, useEffect, useRef, useState } from 'react';

import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { AvailableResult, NativeBiometric } from 'capacitor-native-biometric';
import { SafeArea } from 'capacitor-plugin-safe-area';
import Pages from 'pages';
import { useAuth } from 'react-oidc-context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ERROR_NONE_AUTH_ROUTE } from 'routes/routes';
import { t } from 'tools/i18n';
import useInitialAuthRequestsHook from 'useInitialAuthRequests.hook';
import checkIsRooted from 'utils/checkIsRooted';
import secureStorage from 'utils/secureStorage';
import useFirebase from 'utils/useFirebase.hook';
import useSecureStorage from 'utils/useSecureStorage.hook';

import { secureStorageStateSelector } from 'selectors/secureStorageSlice.selector';
import { init } from 'slices/secureStorageSlice';
import { useAppDispatch, useAppSelector } from 'store';

import { Typography } from 'gazprom-ui-lib';

import UniversalLoader from 'components/universal-loader';
import WithBackground from 'containers/wrappers/with-background';
import WithIdle from 'containers/wrappers/with-idle';
import WithLoader from 'containers/wrappers/with-loader';

import Identifier from 'pages/identifier';
import IdentifierAddPasscode from 'pages/identifier/identifier-add-passcode';
import RegisterSuccess from 'pages/register-success';

import { ReactComponent as LogoGsp5 } from 'assets/logo/logo-gsp-5.svg';
import { ReactComponent as LogoBlue } from 'assets/logo/logo-short-name-row-blue.svg';

import { APP_ID } from 'constants/appId';
import { CODES } from 'constants/errorCodes';
import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

import s from './App.module.scss';
import checkIsActualVersion from './utils/checkIsActualVersion.utils';

if (Capacitor.getPlatform() === 'ios') {
  SafeArea.getSafeAreaInsets().then((data) => {
    const { insets } = data;
    for (const [key, value] of Object.entries(insets)) {
      document.documentElement.style.setProperty(`--safe-area-${key}`, `${value}px`);
    }
  });
}

if (Capacitor.getPlatform() === 'web' || Capacitor.getPlatform() === 'android') {
  document.documentElement.style.setProperty(`--safe-area-bottom`, `0px`);
  document.documentElement.style.setProperty(`--safe-area-top`, `0px`);
}

const AppPrepare = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {
    user,
    isAuthenticated,
    signinSilent,
    isLoading: isAuthLoading,
    activeNavigator,
  } = useAuth();
  const { isStorageLoaded } = useAppSelector(secureStorageStateSelector);
  const dispatch = useAppDispatch();
  const { unregister } = useFirebase();
  const navigate = useNavigate();

  useEffect(() => {
    secureStorage.getAllItems().then((values) => {
      dispatch(init(values));
    });
  }, [dispatch]);

  useEffect(() => {
    CapacitorApp.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      if (event.url.includes(APP_ID)) {
        navigate(event.url.replace(`${APP_ID}://`, '/'));
      }
    });
  }, [navigate]);

  useEffect(() => {
    if (isLoading && !isAuthLoading && activeNavigator !== 'signinSilent' && isStorageLoaded) {
      setIsLoading(false);
    }
  }, [activeNavigator, isAuthLoading, isLoading, isStorageLoaded]);

  useEffect(() => {
    if (!isAuthenticated && user) {
      if (user?.expires_at && Date.now() > user?.expires_at * 1000) {
        signinSilent().catch(() => {
          unregister();
        });
      }
    }
  }, [user, isAuthenticated, isLoading, signinSilent, unregister]);

  useEffect(() => {
    if (!isLoading) {
      const mainLoader = document.getElementById('mainLoader');
      if (mainLoader) {
        mainLoader.style.display = 'none';
      }
      const root = document.getElementById('root');
      if (root) {
        root.style.display = 'block';
      }
    }
  }, [isLoading]);

  if (isLoading) {
    return null;
  }

  return <App />;
};

const App = () => {
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const navigate = useNavigate();

  const [pass] = useSecureStorage(SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_INSTALLED_PASS, '');

  const [isPassVerified, setIsPassVerified] = useState<boolean>(false);
  const [biometricStatus, setBiometricStatus] = useState<null | AvailableResult>(null);
  const [biometricIsLoading, setBiometricIsLoading] = useState<boolean>(true);

  const initPass = useRef<string>(pass);

  const { isLoading: initialRequestsAreLoading } = useInitialAuthRequestsHook();

  const { register } = useFirebase();

  const checkVersion = useCallback(async () => {
    const isActualVersion = await checkIsActualVersion();

    if (!isActualVersion) {
      navigate('/error?code=n002');
    }
  }, [navigate]);

  useEffect(() => {
    Device.getInfo().then((r) => {
      if (r.isVirtual) {
        navigate({
          pathname: ERROR_NONE_AUTH_ROUTE,
          search: `?code=${CODES.VIRTUAL_DEVICE}`,
        });
      }
    });

    if (
      process.env.false?.trim() === 'true' &&
      !Capacitor.isNativePlatform()
    ) {
      navigate({
        pathname: ERROR_NONE_AUTH_ROUTE,
        search: `?code=${CODES.BROWSER_DEVICE}`,
      });
    }
  }, [navigate]);

  useEffect(() => {
    checkIsRooted().then((rooted) => {
      if (rooted) {
        navigate({
          pathname: ERROR_NONE_AUTH_ROUTE,
          search: `?code=${CODES.ROOTED_DEVICE}`,
        });
      } else {
        checkVersion();
      }
    });
  }, [checkVersion, navigate]);

  useEffect(() => {
    register();
  }, [register]);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      NativeBiometric.isAvailable()
        .then(setBiometricStatus)
        .finally(() => setBiometricIsLoading(false));
    } else {
      setBiometricIsLoading(false);
    }
  }, []);

  const handleIdle = () => {
    setIsPassVerified(false);
  };

  const showLoader = initialRequestsAreLoading || biometricIsLoading;

  const loader = (
    <WithBackground>
      <LogoBlue className={s.logoBlue} />
      <div className={s.loaderWrapper}>
        <UniversalLoader />
        <Typography.Text type="secondary" size="14">
          {t('common_load_data')}...
        </Typography.Text>
      </div>
      <LogoGsp5 className={s.logoGsp5} />
    </WithBackground>
  );

  if (process.env.NODE_ENV !== 'development') {
    const showRegisterSuccess = searchParams.get('from_register') !== null;
    if (showRegisterSuccess) {
      return <RegisterSuccess />;
    }

    const showAddIdentifier = !pass && auth?.isAuthenticated;
    if (showAddIdentifier) {
      return <IdentifierAddPasscode biometricStatus={biometricStatus!} />;
    }

    const showIdentifier = !!initPass.current && !isPassVerified && auth?.isAuthenticated;
    if (showIdentifier) {
      return (
        <Identifier setIsPassVerified={setIsPassVerified} biometricStatus={biometricStatus!} />
      );
    }
  }

  return (
    <WithLoader isLoading={showLoader} loader={loader}>
      <WithIdle handleIdle={handleIdle}>
        <Pages />
      </WithIdle>
    </WithLoader>
  );
};

export default AppPrepare;
