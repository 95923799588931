import { Dispatch, SetStateAction } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DOCUMENTS_SIGN_CODE_ROUTE } from 'routes/documents/list';

import initialApiService from 'services/initialApiService';
import {
  useApproveCancelKedoTaskAssignmentMutation,
  useApproveKedoTaskAssignmentMutation,
  useInfoKedoTaskAssignmentMutation,
  useRejectCancelKedoTaskAssignmentMutation,
  useRejectKedoTaskAssignmentMutation,
  useSignKedoTaskAssignmentMutation,
} from 'services/v2/me/task-assignment/kedo-task/kedoTaskApiService';
import { SignKedoTaskAssignmentResponseType } from 'services/v2/me/task-assignment/kedo-task/kedoTaskApiService.types';
import { useAppDispatch } from 'store';

import { handleRequest } from 'gazprom-ui-lib';

import { SERVICE_TAGS } from 'constants/serviceTags';
import { AssignmentType } from 'types/assignment.types';

import { MODAL_TYPE, getFilesKeys } from '../utils';
import { ModalType } from './actionsKedoTask.utils';

interface Props {
  setModalType: Dispatch<SetStateAction<ModalType>>;
  assignment?: AssignmentType;
  handleCertificateCheck: (callback: () => void) => void;
}

const useActions = (props: Props) => {
  const { setModalType, assignment, handleCertificateCheck } = props;

  const params = useParams<{ taskId: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [sign, { isLoading: signIsLoading }] = useSignKedoTaskAssignmentMutation();
  const [reject, { isLoading: rejectIsLoading }] = useRejectKedoTaskAssignmentMutation();
  const [rejectCancel, { isLoading: rejectCancelIsLoading }] =
    useRejectCancelKedoTaskAssignmentMutation();
  const [info, { isLoading: infoIsLoading }] = useInfoKedoTaskAssignmentMutation();
  const [approve, { isLoading: approveIsLoading }] = useApproveKedoTaskAssignmentMutation();
  const [approveCancel, { isLoading: approveCancelIsLoading }] =
    useApproveCancelKedoTaskAssignmentMutation();

  const handleInvalidate = () => {
    dispatch(
      initialApiService?.util?.invalidateTags([
        SERVICE_TAGS.GET_ASSIGNED_TASK_BY_ID,
        SERVICE_TAGS.GET_ASSIGNED_TASKS,
      ]),
    );
  };

  const handleCancellationRejectConfirm = ({ comment }: { comment: string }) => {
    if (params.taskId) {
      const onSuccess = () => {
        setModalType(MODAL_TYPE.REJECTED);
        handleInvalidate();
      };
      rejectCancel({ assignmentId: params.taskId, comment })?.then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const handleCancellationConfirm = () => {
    if (params.taskId) {
      const onSuccess = () => {
        setModalType(MODAL_TYPE.APPROVE);
        handleInvalidate();
      };

      approveCancel({ assignmentId: params.taskId })?.then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const handleRejectConfirm = ({ comment }: { comment: string }) => {
    if (params.taskId) {
      const onSuccess = () => {
        setModalType(MODAL_TYPE.REJECTED);
        handleInvalidate();
      };

      reject({ assignmentId: params.taskId, comment })?.then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const handleInfo = () => {
    if (assignment) {
      const onSuccess = () => {
        handleInvalidate();
        setModalType(MODAL_TYPE.APPROVE);
      };

      info({ assignmentId: assignment.id }).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const handleApprove = () => {
    if (assignment) {
      const onSuccess = () => {
        handleInvalidate();
        setModalType(MODAL_TYPE.APPROVE);
      };

      approve({ assignmentId: assignment.id }).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const handleSign = () => {
    if (assignment) {
      const onSuccess = (res?: SignKedoTaskAssignmentResponseType) => {
        if (res) {
          const state = {
            cameFrom: location.pathname,
            type: 'v2kedoTaskTypeSign',
            operationId: res.operationId,
            modalType: MODAL_TYPE.FAMILIARIZED,
          };

          navigate(DOCUMENTS_SIGN_CODE_ROUTE, { state });
        }
      };

      const body = {
        assignmentId: assignment.id,
        taskId: assignment.kedoTask?.id,
        fileKeys: getFilesKeys(assignment?.files ?? []),
      };

      handleCertificateCheck(() =>
        sign(body).then(
          handleRequest({
            onSuccess,
          }),
        ),
      );
    }
  };

  const isLoading =
    signIsLoading ||
    rejectIsLoading ||
    rejectCancelIsLoading ||
    infoIsLoading ||
    approveIsLoading ||
    approveCancelIsLoading;

  return {
    isLoading,
    handleInfo,
    handleSign,
    handleApprove,
    handleRejectConfirm,
    handleCancellationConfirm,
    handleCancellationRejectConfirm,
  };
};

export default useActions;
