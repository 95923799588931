import React, { useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules.utils';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetLeadersQuery } from 'services/me/meApiService';

import { Flex, Form, Select, SelectProps } from 'gazprom-ui-lib';

import { LeaderType } from 'types/employee.types';

import { FORM_ENUM } from '../../businessTripForm.utils';
import s from './Approvers.module.scss';
import PopoverTitle from './containers/popover-title';
import SelectWithSearch from './containers/select-with-search';

export type ModalType = FORM_ENUM.APPROVER | FORM_ENUM.CONFIRMER | null;

const Approvers = () => {
  const [modalType, setModalType] = useState<ModalType>(null);

  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: leadersData, isFetching: leadersIsFetching } = useGetLeadersQuery(
    { myEmployeeId: selectedEmployee?.id! },
    { skip: !selectedEmployee },
  );
  const handleModalOpen =
    (modalType: ModalType): SelectProps['onClick'] =>
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setModalType(modalType);
    };

  const options = leadersData?.map(transformLeadersIntoOptions);

  return (
    <>
      <Flex vertical className={s.wrapper}>
        <Flex vertical gap="12">
          <PopoverTitle
            popoverTitle={t('common_approval')}
            popoverContent={t('documents_leaders_first_leader')}
          />
          <Form.Item name={FORM_ENUM.APPROVER} rules={[formRules.required]}>
            <Select
              onClick={handleModalOpen(FORM_ENUM.APPROVER)}
              open={false}
              disabled={leadersIsFetching}
              options={options}
            />
          </Form.Item>
        </Flex>
        <Flex vertical gap="12">
          <PopoverTitle
            popoverTitle={t('common_confirmation')}
            popoverContent={t('documents_order_approver')}
          />
          <Form.Item name={FORM_ENUM.CONFIRMER}>
            <Select
              onClick={handleModalOpen(FORM_ENUM.CONFIRMER)}
              open={false}
              disabled={leadersIsFetching}
              options={options}
            />
          </Form.Item>
        </Flex>
      </Flex>
      <SelectWithSearch
        modalType={modalType}
        setModalType={setModalType}
        leadersData={leadersData}
        leadersIsFetching={leadersIsFetching}
      />
    </>
  );
};

const transformLeadersIntoOptions = (leader: LeaderType) => {
  const { person } = leader;
  const { firstName, lastName, patronymic } = person;

  return { value: leader.employeeId, label: `${firstName} ${lastName} ${patronymic}` };
};

export default Approvers;
