import { useEffect, useState } from 'react';

import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useLazyGetAssignedToMeStatementsQuery } from 'services/kedo/kedoApiService';
import { GetAssignedToMeStatementsResponseType } from 'services/kedo/kedoApiService.types';

import { handleRequest } from 'gazprom-ui-lib';

import { PaginationPropsType } from 'types/pagination.types';
import { SORT_ENUM } from 'types/sort.types';

import { SEGMENT_IN_PROCESS } from './myAssignments.utils';

interface UseGetDataProps {
  pagination: PaginationPropsType;
  segment: string;
}

const useGetData = (props: UseGetDataProps) => {
  const { pagination, segment } = props;

  const [selectedEmployee] = useFullSelectedEmployee();

  const [assignedStatements, setAssignedStatements] = useState<
    GetAssignedToMeStatementsResponseType['items']
  >([]);

  const [
    getAssignedTasks,
    {
      data: assignedStatementsData,
      isLoading: assignedStatementsIsLoading,
      isFetching: assignedStatementsIsFetching,
      isUninitialized,
    },
  ] = useLazyGetAssignedToMeStatementsQuery();

  useEffect(() => {
    if (segment && selectedEmployee) {
      const onSuccess = (res?: GetAssignedToMeStatementsResponseType) => {
        if (res) {
          if (res.page === 1) {
            setAssignedStatements(res.items);
          } else {
            setAssignedStatements((prevState) => prevState.concat(res.items));
          }
        }
      };

      getAssignedTasks({
        ...pagination,
        sort: [{ order: SORT_ENUM.DESC, property: 'kedo_task_assignment.createdAt' }],
        statuses: segment === SEGMENT_IN_PROCESS ? ['WAITING'] : ['APPROVED', 'REJECTED'],
      }).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  }, [getAssignedTasks, pagination, segment, selectedEmployee]);

  return {
    assignedStatements,
    totalCount: assignedStatementsData?.totalCount,
    assignedStatementsIsLoading,
    assignedStatementsIsFetching,
    isUninitialized,
  };
};

export default useGetData;
