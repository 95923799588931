import { Dispatch, SetStateAction } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { DOCUMENTS_SIGN_CODE_ROUTE } from 'routes/documents/list';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { activeKedoTaskSelector } from 'selectors/kedoSlice.selector';
import { useSendKedoTaskMutation } from 'services/kedo/kedoApiService';
import { useSignMutation } from 'services/me/meApiService';
import { useAppSelector } from 'store';

import { handleRequest } from 'gazprom-ui-lib';

interface UseHandleSignProps {
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

const useHandleSign = (props: UseHandleSignProps) => {
  const { setIsModalVisible } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [selectedEmployee] = useFullSelectedEmployee();

  const activeKedoTask = useAppSelector(activeKedoTaskSelector);

  const [sign, { isLoading: signIsLoading }] = useSignMutation();
  //TODO REMOVE
  const [sendTask, { isLoading: sendTaskIsLoading }] = useSendKedoTaskMutation();

  const handleSign = () => {
    //TODO REMOVE
    if (location.state?.mock) {
      const onSuccess = () => {
        const cameFrom = location.state?.cameFrom;
        if (cameFrom) {
          return navigate(cameFrom, { state: location.state });
        } else {
          navigate(-1);
        }
      };

      sendTask({
        selectedEmployee: selectedEmployee?.id!,
        id: activeKedoTask?.taskId!,
      }).then(
        handleRequest({
          onSuccess,
        }),
      );
    } else {
      const onSuccess = (data?: Record<string, unknown>) => {
        if (data) {
          const state = { ...data, ...(location.state || {}) };

          navigate(DOCUMENTS_SIGN_CODE_ROUTE, {
            state,
          });
        }
      };

      const onError = () => {
        setIsModalVisible(true);
      };

      sign({
        fileKeys: activeKedoTask?.filesToSign.map((file: { fileKey: string }) => file.fileKey),
      })
        .then(
          handleRequest({
            onSuccess,
            onError,
          }),
        )
        .catch(() => {
          setIsModalVisible(true);
        });
    }
  };

  return {
    handleSign,
    isLoading: signIsLoading || sendTaskIsLoading,
  };
};

export default useHandleSign;
