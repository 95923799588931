import { useNavigate } from 'react-router-dom';
import { getDocumentsBusinessTripDetailsRoute } from 'routes/documents/list';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useLazyPrepareBusinessTripQuery } from 'services/me/work-tour/workTourApiService';
import { PrepareBusinessTripPropsType } from 'services/me/work-tour/workTourApiService.types';

import { handleRequest } from 'gazprom-ui-lib';

import { BusinessTripFormProps } from 'containers/business-trip-form/businessTripForm';
import {
  FORM_ENUM,
  FormCreateResponse,
  formatFormDate,
} from 'containers/business-trip-form/businessTripForm.utils';

import useHandleCreateEdit from '../useHandleCreateEdit.hook';

const useHandleSubmit = () => {
  const navigate = useNavigate();

  const [selectedEmployee] = useFullSelectedEmployee();

  const [prepareTask, { isLoading: prepareTaskIsLoading }] = useLazyPrepareBusinessTripQuery();

  const handleNavigate = (id: string) => {
    navigate(getDocumentsBusinessTripDetailsRoute(id), { state: { modalType: 'created' } });
  };

  const { isLoading, handleContinueBusinessTripCreateEdit } = useHandleCreateEdit({
    onSuccess: handleNavigate,
  });

  const handleSubmit: BusinessTripFormProps['handleSubmit'] = (initialFormResponse) => {
    const formResponse = initialFormResponse as FormCreateResponse;

    const isCreateAvailable = selectedEmployee;

    if (isCreateAvailable) {
      const prepareTaskRequestBody: PrepareBusinessTripPropsType = {
        myEmployeeId: selectedEmployee?.id,
        [FORM_ENUM.FROM]: formatFormDate(formResponse[FORM_ENUM.FROM]),
        [FORM_ENUM.TO]: formatFormDate(formResponse[FORM_ENUM.TO]),
        [FORM_ENUM.DESTINATION]: formResponse[FORM_ENUM.DESTINATION],
        [FORM_ENUM.PURPOSE]: formResponse[FORM_ENUM.PURPOSE],
        [FORM_ENUM.SOURCE]: formResponse[FORM_ENUM.SOURCE],
        [FORM_ENUM.APPROVER]: formResponse[FORM_ENUM.APPROVER],
      };

      if (formResponse[FORM_ENUM.CONFIRMER]) {
        prepareTaskRequestBody[FORM_ENUM.CONFIRMER] = formResponse[FORM_ENUM.CONFIRMER];
      }

      if (formResponse[FORM_ENUM.DAY_OFF_FROM]) {
        prepareTaskRequestBody[FORM_ENUM.DAY_OFF_FROM] = formatFormDate(
          formResponse[FORM_ENUM.DAY_OFF_FROM],
        );
      }
      if (formResponse[FORM_ENUM.DAY_OFF_TO]) {
        prepareTaskRequestBody[FORM_ENUM.DAY_OFF_TO] = formatFormDate(
          formResponse[FORM_ENUM.DAY_OFF_TO],
        );
      }
      if (formResponse[FORM_ENUM.PURPOSE_SPECIFY]) {
        prepareTaskRequestBody[FORM_ENUM.PURPOSE_SPECIFY] = formResponse[FORM_ENUM.PURPOSE_SPECIFY];
      }

      prepareTask(prepareTaskRequestBody).then(
        handleRequest({
          onSuccess: handleContinueBusinessTripCreateEdit,
        }),
      );
    }
  };

  return {
    handleSubmit,
    isLoading: prepareTaskIsLoading || isLoading,
  };
};

export default useHandleSubmit;
