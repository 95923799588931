export enum SERVICE_TAGS {
  GET_NOTIFICATION_BY_ID = 'GET_NOTIFICATION_BY_ID',
  GET_ARTICLE_BY_ID = 'GET_ARTICLE_BY_ID',
  GET_FEEDBACKS = 'GET_FEEDBACKS',
  GET_FEEDBACK_BY_ID = 'GET_FEEDBACK_BY_ID',
  GET_SURVEYS = 'GET_SURVEYS',
  GET_SURVEY_STATUS = 'GET_SURVEY_STATUS',
  GET_KEDO_TASKS = 'GET_KEDO_TASKS',
  GET_KEDO_TASK_BY_ID = 'GET_KEDO_TASK_BY_ID',
  GET_CERTIFICATES = 'GET_CERTIFICATES',
  GET_EMPLOYEES = 'GET_EMPLOYEES',
  GET_GUIDE_HISTORY = 'GET_GUIDE_HISTORY',
  GET_ASSIGNED_TASK_BY_ID = 'GET_ASSIGNED_TASK_BY_ID',
  GET_ASSIGNED_TASKS = 'GET_ASSIGNED_TASKS',
  GET_EMPLOYEE_PERSONAL_DATA = 'GET_EMPLOYEE_PERSONAL_DATA',
  GET_ARTICLE_COMMENTS = 'GET_ARTICLE_COMMENTS',
  GET_BUSINESS_TRIPS = 'GET_BUSINESS_TRIPS',
  GET_BUSINESS_TRIP_BY_ID = 'GET_BUSINESS_TRIP_BY_ID',
}

export enum SERVICE_API_V2_TAGS {}

export const SERVICE_TAGS_LIST = Object.values(SERVICE_TAGS);
export const SERVICE_API_V2_TAGS_LIST = Object.values(SERVICE_API_V2_TAGS);
