import { useContext, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import useCertificate from 'utils/useCertificate.hook';

import initialApiService from 'services/initialApiService';
import { useAppDispatch } from 'store';

import CancellationConfirmModal from 'containers/modals/cancellation-confirm-modal';
import NoCertificateModal from 'containers/modals/no-certificate-modal';

import { AssignmentContext } from '../../documentsAssignment';
import s from './Actions.module.scss';
import ActionsKedoDocument from './containers/actions-kedo-document';
import ActionsKedoReviewTask from './containers/actions-kedo-review-task';
import ActionsKedoTask from './containers/actions-kedo-task';
import ActionsWorkTour from './containers/actions-work-tour';

const Actions = () => {
  const assignment = useContext(AssignmentContext);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [modalType, setModalType] = useState<string>('');

  const { isActive: isActiveCertificate } = useCertificate();

  useEffect(() => {
    if (location.state?.modalType) {
      setModalType(location.state.modalType);

      const invalidateTags = location.state?.invalidateTags;
      if (Array.isArray(invalidateTags)) {
        dispatch(initialApiService?.util?.invalidateTags(invalidateTags));
      }
      navigate(location.pathname, { replace: true });
    }
  }, [
    dispatch,
    location.pathname,
    location.state?.invalidateTags,
    location.state?.modalType,
    navigate,
  ]);

  const handleModalClose = () => {
    setModalType('');
  };

  const handleCertificateCheck = (callback: () => void) => {
    if (isActiveCertificate) {
      callback();
    } else {
      setModalType('noCertificate');
    }
  };

  if (!assignment) {
    return null;
  }

  let content = null;

  // Ввиду абсолютного рандома как у нас экшены будут выглядеть завтра приходится делать
  // практически copy-paste, но иначе мы попадём ровно в ту же ситуацию, когда расширение === боль
  switch (assignment.entityType) {
    case 'REVIEW_TASK': {
      content = <ActionsKedoReviewTask handleCertificateCheck={handleCertificateCheck} />;
      break;
    }
    case 'KEDO_TASK': {
      content = <ActionsKedoTask handleCertificateCheck={handleCertificateCheck} />;
      break;
    }
    case 'DOCUMENT': {
      content = <ActionsKedoDocument handleCertificateCheck={handleCertificateCheck} />;
      break;
    }
    case 'WORK_TOUR': {
      content = <ActionsWorkTour handleCertificateCheck={handleCertificateCheck} />;
      break;
    }
  }

  return (
    <>
      <div className={s.wrapper}>{content}</div>
      <NoCertificateModal open={modalType === 'noCertificate'} handleClose={handleModalClose} />
      <CancellationConfirmModal
        isOpen={modalType === 'cancellationSuccess'}
        onClickHandler={handleModalClose}
        onCancelHandler={handleModalClose}
      />
    </>
  );
};

export default Actions;
