import React, { FC } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { getDocumentsBusinessTripDetailsRoute } from 'routes/documents/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetBusinessTripByIdQuery } from 'services/me/work-tour/workTourApiService';

import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithPullToRefresh from 'containers/wrappers/with-pull-to-refresh';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { ApprovalMapItemType } from 'types/businessTrip.types';

import ApprovalCard from './containers/approval-card';

const DocumentsBusinessTripApproversList: FC = () => {
  const { id } = useParams<{ id: string }>();

  const [selectedEmployee] = useFullSelectedEmployee();

  const isFetchAvailable = selectedEmployee && id;
  const {
    data: businessTrip,
    isLoading: businessTripIsLoading,
    refetch: refetchBusinessTrip,
  } = useGetBusinessTripByIdQuery(
    isFetchAvailable ? { id, myEmployeeId: selectedEmployee.id } : skipToken,
  );

  const headerChildren = (
    <>
      <Header.GoBack to={getDocumentsBusinessTripDetailsRoute(id!)} useDefaultGoBack />
      <Header.Label label={t('documents_business_trip_approvers_list')} />
    </>
  );

  const { approvalMap } = businessTrip ?? {};

  const isSingleCycle = approvalMap && Object.keys(approvalMap).length === 1;

  const renderApprovalItem = ([cycleNumber, approvals]: [
    cycleNumber: string,
    approvals: ApprovalMapItemType[],
  ]) => (
    <ApprovalCard
      key={cycleNumber}
      cycleNumber={cycleNumber}
      approvals={approvals}
      isExpandedDefault={isSingleCycle}
    />
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithPullToRefresh onRefresh={refetchBusinessTrip}>
          <WithBackground>
            <WithLoader isLoading={businessTripIsLoading}>
              {approvalMap && Object.entries(approvalMap).map(renderApprovalItem)}
            </WithLoader>
          </WithBackground>
        </WithPullToRefresh>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsBusinessTripApproversList;
