import { userManager } from 'utils/keycloak';
import { prepareQueryParams } from 'utils/prepareQueryParams';

export const getNotificationButtonUrl = async (url: string, selectedEmployeeId: string) => {
  const user = await userManager.getUser();
  const token = user?.access_token as string;

  return await fetch(
    `${process.env.https://api-test.dev.tockl.ru/api/v1/}${url}${prepareQueryParams({
      myEmployeeId: selectedEmployeeId,
    })}`,
    {
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    },
  ).then((r) => r.text());
};
