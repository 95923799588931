import React, { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, Card, Flex, Form, FormInstance, Typography } from 'gazprom-ui-lib';

import s from './BusinessTripForm.module.scss';
import {
  BusinessTripContext,
  FormCancelResponse,
  FormCreateResponse,
  FormEditResponse,
} from './businessTripForm.utils';
import Approvers from './containers/approvers';
import Dates from './containers/dates';
import DayOff from './containers/day-off';
import Destination from './containers/destination';
import Purpose from './containers/purpose';
import Source from './containers/source';

export interface BusinessTripFormProps {
  handleSubmit: (formResponse: FormCreateResponse | FormEditResponse | FormCancelResponse) => void;
  isLoading: boolean;
  hideStartDate?: boolean;
  type: 'edit' | 'create' | 'cancel';
  form?: FormInstance;
}

const BusinessTripForm: FC<BusinessTripFormProps> = (props) => {
  const { handleSubmit, isLoading, type, form: formFromProps, hideStartDate } = props;

  const [form] = Form.useForm();

  const formToUse = formFromProps ?? form;

  let formContent = (
    <>
      <Card>
        <Dates />
        <Destination />
        <Purpose />
        <Source />
      </Card>
      <DayOff />
      <Card>
        <Typography.Title level={5}>{t('documents_approvers')}</Typography.Title>
        <Approvers />
      </Card>
    </>
  );
  if (type === 'edit') {
    formContent = (
      <Card>
        <Purpose />
        <Dates hideStartDate={hideStartDate} />
        <Destination />
        <DayOff />
      </Card>
    );
  }
  if (type === 'cancel') {
    formContent = (
      <Card>
        <Purpose />
      </Card>
    );
  }

  return (
    <Form form={formToUse} onFinish={handleSubmit}>
      <Flex vertical gap="16">
        <BusinessTripContext.Provider value={{ type }}>{formContent}</BusinessTripContext.Provider>
      </Flex>
      <div className={s.fixedCard}>
        <Button
          loading={isLoading}
          htmlType="submit"
          fullWidth
          rightIcon="arrowRight"
          size="large"
          type="primary">
          {t('common_send')}
        </Button>
      </div>
    </Form>
  );
};

export default BusinessTripForm;
