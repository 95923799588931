import {
  BaseQueryApi,
  BaseQueryExtraOptions,
  BaseQueryFn,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { userManager } from 'utils/keycloak';

import { RootState } from 'store';

import SECURE_STORAGE_KEYS_ENUM from 'constants/secureStorageKeys';

const AUTH_ERROR_CODES = [401];

export const baseQueryWithReauth =
  (config: FetchBaseQueryArgs) =>
    async (
      args: FetchArgs | string,
      api: BaseQueryApi,
      extraOptions: BaseQueryExtraOptions<BaseQueryFn>,
    ) => {
      let result = await fetchBaseQuery(config)(args, api, extraOptions);

      if (result.error && AUTH_ERROR_CODES.includes(result.error.status as number)) {
        try {
          await userManager.signinSilent();
          result = await fetchBaseQuery(config)(args, api, extraOptions);
        } catch (e) {
          await userManager.signoutSilent();
        }
      }

      return result;
    };

const IGNORE_ENDPOINTS = ['getCertificates', 'getEmployees', 'getHeroConfig'];

const prepareHeaders: FetchBaseQueryArgs['prepareHeaders'] = async (
  headers,
  { getState, endpoint },
) => {
  const user = await userManager.getUser();
  const token = user?.access_token;

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  const { secureStorageSlice } = getState() as RootState;
  const myEmployeeId =
    secureStorageSlice.data[SECURE_STORAGE_KEYS_ENUM.GZP_MOBILE_SELECTED_EMPLOYEE];

  if (myEmployeeId && !IGNORE_ENDPOINTS.includes(endpoint)) {
    headers.set('X-My-Employee-Id', myEmployeeId);
  }

  return headers;
};

export const BASE_QUERY_WITH_AUTH = baseQueryWithReauth({
  baseUrl: process.env.https://api-test.dev.tockl.ru/api/v1/,
  credentials: 'include',
  mode: 'cors',
  prepareHeaders,
});

export const BASE_QUERY_WITH_AUTH_V2 = baseQueryWithReauth({
  baseUrl: process.env.https://api.dev.tockl.ru/api/v2/,
  credentials: 'include',
  mode: 'cors',
  prepareHeaders,
});
