import {
  MORE_ABOUT_COMPANY_ROUTE,
  MORE_EDUCATION_ROUTE,
  MORE_FAQ_ROUTE,
  MORE_FEEDBACK_ROUTE,
  MORE_SURVEYS_ROUTE,
} from 'routes/more/list';

import { TIconsLiteral } from 'gazprom-ui-lib';

export type CardType = {
  to: string;
  title: string;
  description: string;
  icon: TIconsLiteral;
  hidden?: boolean;
  showDot?: boolean;
};

export const MORE_CARDS: CardType[] = [
  {
    to: MORE_EDUCATION_ROUTE,
    title: 'common_education',
    description: 'common_trainings',
    icon: 'educationFilled',
  },
  {
    to: MORE_SURVEYS_ROUTE,
    title: 'common_polls',
    description: 'survey_take_survey',
    icon: 'questionnairesFilled',
  },
  // TODO innovation link
  // {
  //   to: ,
  //   title: 'common_innovations',
  //   description: 'common_innovations_description',
  //   icon: '',
  // },
  {
    to: MORE_FEEDBACK_ROUTE,
    title: 'common_thanks_for_work',
    description: 'feedback_write_read',
    icon: 'gratitudeFilled',
  },
  {
    to: MORE_FAQ_ROUTE,
    title: 'common_questions',
    description: 'profile_help_and_support_descriptions',
    icon: 'faqFilled',
  },
  {
    to: MORE_ABOUT_COMPANY_ROUTE,
    title: 'common_about_company',
    description: 'common_organization_info',
    icon: 'home',
  },
];
