import dayjs from 'dayjs';

import { Flex, Typography } from 'gazprom-ui-lib';

import { AssignmentWorkTourType } from 'types/assignment.types';

import s from './WorkTourDetails.module.scss';
import { WORK_TOUR_ATTRIBUTES, WorkTourAttributeType } from './workTourDetails.utils';

interface Props {
  workTour?: AssignmentWorkTourType;
}

const WorkTourDetails = (props: Props) => {
  const { workTour } = props;

  const renderAttributeValue = (value: string | number, isDate?: boolean) => {
    if (!value) {
      return '';
    }

    if (isDate) {
      return dayjs(value).format('DD.MM.YYYY');
    }

    return value;
  };

  if (!workTour) {
    return null;
  }

  const renderAttribute = (attribute: WorkTourAttributeType) => {
    const { attributeKey, attributeName, isDate } = attribute;

    return (
      <Flex vertical key={attributeKey}>
        <Typography.Text size="12" type="secondary">
          {attributeName}
        </Typography.Text>
        <Typography.Title level={5}>
          {renderAttributeValue(workTour[attributeKey], isDate)}
        </Typography.Title>
      </Flex>
    );
  };

  return (
    <Flex vertical gap="24" className={s.wrapper}>
      {WORK_TOUR_ATTRIBUTES.map(renderAttribute)}
    </Flex>
  );
};

export default WorkTourDetails;
