import {
  getDocumentsInquiriesRequestRoute,
  getDocumentsStatementRequestRoute,
} from 'routes/documents/list';
import { getSocialPolicyProgramApplyRoute } from 'routes/guide/list';

import { KEDO_TASK_FILE_TYPES, KedoTask, KedoTaskFile } from 'types/documents.types';

export const getFixRequestErrorRoute = (
  task: KedoTask,
  isSocialPolicy: boolean,
  isStatement: boolean,
): string => {
  const { statement, category } = task;

  let route = getDocumentsInquiriesRequestRoute(statement.id);

  if (isSocialPolicy) {
    route = getSocialPolicyProgramApplyRoute(category.id, 'unknown', statement.id);
  }

  if (isStatement) {
    route = getDocumentsStatementRequestRoute(statement.id);
  }

  return route;
};

export const getFinalFiles = (file: KedoTaskFile) => file.type === KEDO_TASK_FILE_TYPES.FINAL_DOC;

export type ModalTypes = '' | 'cancel' | 'cancelConfirm' | 'cancelled' | 'created';
