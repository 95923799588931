import { useContext } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { DOCUMENTS_SIGN_CODE_ROUTE } from 'routes/documents/list';

import initialApiService from 'services/initialApiService';
import {
  useApproveWorkTourAssignmentMutation,
  useRejectWorkTourAssignmentMutation,
  useRevisionWorkTourAssignmentMutation,
  useSignWorkTourAssignmentMutation,
} from 'services/v2/me/task-assignment/work-tour/workTourApiService';
import {
  SignWorkTourAssignmentPropsType,
  SignWorkTourAssignmentResponseType,
} from 'services/v2/me/task-assignment/work-tour/workTourApiService.types';
import { useAppDispatch } from 'store';

import { handleRequest } from 'gazprom-ui-lib';

import { AssignmentContext } from 'containers/document-assignment/documentsAssignment';

import { SERVICE_TAGS } from 'constants/serviceTags';

import { MODAL_TYPE, getFilesKeys } from '../utils';

interface Props {
  handleCertificateCheck: (callback: () => void) => void;
  handleOpenCorrectionModal: () => void;
  handleOpenApproveModal: () => void;
}

const useActions = (props: Props) => {
  const { handleCertificateCheck, handleOpenCorrectionModal, handleOpenApproveModal } = props;

  const assignment = useContext(AssignmentContext);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [sign, { isLoading: signIsLoading }] = useSignWorkTourAssignmentMutation();
  const [approve, { isLoading: approveIsLoading }] = useApproveWorkTourAssignmentMutation();
  const [, { isLoading: rejectIsLoading }] = useRejectWorkTourAssignmentMutation();
  const [revision, { isLoading: revisionIsLoading }] = useRevisionWorkTourAssignmentMutation();

  const handleInvalidate = () => {
    dispatch(
      initialApiService?.util?.invalidateTags([
        SERVICE_TAGS.GET_ASSIGNED_TASK_BY_ID,
        SERVICE_TAGS.GET_ASSIGNED_TASKS,
      ]),
    );
  };

  const handleCorrectionSubmit = (formResponse: { comment: string }) => {
    if (assignment) {
      const { comment } = formResponse;

      const onSuccess = () => {
        handleInvalidate();
        handleOpenCorrectionModal();
      };

      revision({ assignmentId: assignment.id, comment }).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const handleApprove = () => {
    if (assignment) {
      const onSuccess = () => {
        handleInvalidate();
        handleOpenApproveModal();
      };

      approve({ assignmentId: assignment.id }).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const handleFamiliarize = () => {
    if (assignment?.workTour) {
      const onSuccess = (res?: SignWorkTourAssignmentResponseType) => {
        if (res) {
          const state = {
            cameFrom: location.pathname,
            type: 'v2WorkTourTypeSign',
            operationId: res.operationId,
            modalType: MODAL_TYPE.FAMILIARIZED,
          };

          navigate(DOCUMENTS_SIGN_CODE_ROUTE, { state });
        }
      };

      const body: SignWorkTourAssignmentPropsType = {
        assignmentId: assignment.id,
        workTourId: assignment.workTour.id,
        fileKeys: getFilesKeys(assignment.workTour.files ?? []),
      };

      handleCertificateCheck(() =>
        sign(body).then(
          handleRequest({
            onSuccess,
          }),
        ),
      );
    }
  };

  const isLoading = signIsLoading || approveIsLoading || rejectIsLoading || revisionIsLoading;

  return {
    isLoading,
    handleCorrectionSubmit,
    handleApprove,
    handleFamiliarize,
  };
};

export default useActions;
