import React, { useCallback, useEffect, useState } from 'react';

import { App as CapacitorApp } from '@capacitor/app';
import DOMPurify from 'isomorphic-dompurify';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetNotificationByIdQuery } from 'services/notification/notificationApiService';

import { Button, Icon, ModalDefault, Tag, Typography } from 'gazprom-ui-lib';

import Card from 'containers/card';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import { NotificationType } from 'types/notifications.types';

import { formatDate } from '../notifications.utils';
import NotificationsSingleReply from './notifications-single-reply';
import NotificationsSingleRequestCall from './notifications-single-request-call';
import s from './notifications-single.module.scss';
import { getNotificationButtonUrl } from './notifications-single.utils';

const NotificationsSingle = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data: notification } = useGetNotificationByIdQuery(id!);
  const [replyVisible, setReplyVisible] = useState(false);
  const [requestCallVisible, setRequestCallVisible] = useState(false);
  const [surveyLink, setSurveyLink] = useState('');

  const [modalType, setModalType] = useState<'' | 'requiresFeedback'>('');

  const handleModalClose = () => {
    setModalType('');
  };

  const replySent = !!notification?.feedback;
  const callbackRequested = !!notification?.callBack;
  const requiresCallback = !!notification?.requiresFeedback;

  const handleGoBack = useCallback(() => {
    if (requiresCallback && !replySent) {
      setModalType('requiresFeedback');
    } else {
      navigate(-1);
    }
  }, [navigate, replySent, requiresCallback]);

  useEffect(() => {
    CapacitorApp.addListener('backButton', handleGoBack);

    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, [handleGoBack]);

  const [selectedEmployee] = useFullSelectedEmployee();

  const headerChildren = (
    <>
      <Header.GoBackButton onClick={handleGoBack} />
      <Header.Label label={t('common_notifications')} />
    </>
  );

  const getButtonUrl = useCallback(
    async (notification: NotificationType) => {
      const match = notification.body.match(/<Button href=“(.*)” title/);
      if (match && match[1] && selectedEmployee?.id) {
        const url = match[1].replace('api/v1', 'anketa-link');
        try {
          const notificationButtonUrl = await getNotificationButtonUrl(url, selectedEmployee.id);
          setSurveyLink(notificationButtonUrl);
        } catch (e) {}
      }
    },
    [selectedEmployee?.id],
  );

  useEffect(() => {
    if (notification) {
      getButtonUrl(notification);
    }
  }, [getButtonUrl, notification]);

  if (replyVisible)
    return <NotificationsSingleReply id={id!} handleBack={() => setReplyVisible(false)} />;
  if (requestCallVisible)
    return (
      <NotificationsSingleRequestCall id={id!} handleBack={() => setRequestCallVisible(false)} />
    );

  if (notification) {
    const { title, allowCallback, requiresFeedback, allowFeedback, createdAt, body, sender } =
      notification;
    const showFeedbackButton = replySent || allowFeedback;
    const showCallbackButton = callbackRequested || allowCallback;

    const showRequiresFeedback = showFeedbackButton && requiresFeedback;
    const requiresFeedbackTagChildren = (
      <>
        <Icon name={replySent ? 'ok' : 'mail'} />
        <span>
          {t(replySent ? 'notifications_reply_accepted' : 'notifications_requires_feedback')}
        </span>
      </>
    );

    return (
      <WithStatusBar>
        <WithNavigation headerChildren={headerChildren}>
          <WithBackground>
            <Card withPadding>
              <div className={s.container}>
                <div className={s.header}>
                  <Typography.Text size="12" type="secondary">
                    {formatDate(createdAt)}
                  </Typography.Text>
                  <Typography.Title level={3}>{title}</Typography.Title>
                  <Typography.Text size="14" type="secondary">
                    {sender.senderName}
                  </Typography.Text>
                </div>
                {showRequiresFeedback && (
                  <Tag
                    type={replySent ? 'success' : 'danger'}
                    children={requiresFeedbackTagChildren}
                    className={s.tag}
                  />
                )}
                <Typography.Title level={5} className={s.description}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(body, {
                        FORBID_TAGS: ['button'],
                      }),
                    }}
                  />
                </Typography.Title>
                {surveyLink && (
                  <Button
                    type="primary"
                    size="large"
                    rightIcon="arrowRight"
                    onClick={() => navigate(surveyLink)}>
                    {t('survey_pass')}
                  </Button>
                )}
                {showFeedbackButton && (
                  <Button
                    type="primary"
                    htmlType="button"
                    size="large"
                    fullWidth
                    disabled={replySent}
                    leftIcon={replySent ? <Icon name="ok" /> : undefined}
                    rightIcon={replySent ? undefined : <Icon name="arrowRight" />}
                    onClick={() => setReplyVisible(true)}
                    className={s.button}>
                    {replySent ? t('notifications_reply_sent_short') : t('notifications_reply')}
                  </Button>
                )}
                {showCallbackButton && (
                  <Button
                    type="secondary"
                    htmlType="button"
                    size="large"
                    fullWidth
                    disabled={callbackRequested}
                    leftIcon={callbackRequested ? <Icon name="ok" /> : <Icon name="phoneFilled" />}
                    onClick={() => setRequestCallVisible(true)}>
                    {callbackRequested
                      ? t('notifications_call_requested_short')
                      : t('notifications_request_call_short')}
                  </Button>
                )}
              </div>
            </Card>
            <ModalDefault open={modalType === 'requiresFeedback'} onCancel={handleModalClose}>
              <ModalDefault.Icon name="mail" type="danger" />
              <ModalDefault.Title>{t('notifications_requires_feedback')}</ModalDefault.Title>
              <ModalDefault.Description>
                {t('notifications_requires_feedback_description')}
              </ModalDefault.Description>
              <ModalDefault.Actions>
                <Button size="large" fullWidth onClick={handleModalClose}>
                  {t('common_ok')}
                </Button>
              </ModalDefault.Actions>
            </ModalDefault>
          </WithBackground>
        </WithNavigation>
      </WithStatusBar>
    );
  }

  return null;
};

export default NotificationsSingle;
