import { useParams } from 'react-router-dom';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetAssignedToMeStatementByIdQuery } from 'services/kedo/kedoApiService';

import DocumentsAssignment from 'containers/document-assignment';

import { KEDO_COPY_FILE_TYPES, KedoTaskFile } from 'types/documents.types';

import WorkTourDetails from './containers/work-tour-details';

const DocumentsAssignmentDetails = () => {
  const params = useParams<{ taskId: string }>();

  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: assignment } = useGetAssignedToMeStatementByIdQuery(
    {
      taskId: params?.taskId!,
    },
    { skip: !params?.taskId || !selectedEmployee?.id },
  );

  const filesToShow = (assignment?.files as KedoTaskFile[]) ?? [];

  const { id: kedoTaskId } = assignment?.kedoTask ?? {};
  const { files: kedoReviewFiles, id: kedoReviewTaskId } = assignment?.kedoReviewTask ?? {};
  const { files: kedoDocumentFiles, id: kedoDocumentId } = assignment?.kedoDocument ?? {};
  const { files: workTourFiles, id: workTourId } = assignment?.workTour || {};

  const isCancellationAvailable = assignment?.type === 'CANCEL_ORG_REQUEST';

  const showCancellationComment = isCancellationAvailable && !!assignment?.comment;

  switch (assignment?.entityType) {
    case 'DOCUMENT': {
      return (
        <DocumentsAssignment>
          <DocumentsAssignment.DetailsWrapper>
            <DocumentsAssignment.DetailsHeader />
            <DocumentsAssignment.Content>
              <DocumentsAssignment.Employee />
              <DocumentsAssignment.Attributes />
              <DocumentsAssignment.CancelComment showComment={showCancellationComment} />
              <DocumentsAssignment.Files
                files={kedoDocumentFiles}
                id={kedoDocumentId}
                type={KEDO_COPY_FILE_TYPES.KEDO_DOCUMENT}
              />
            </DocumentsAssignment.Content>
            <DocumentsAssignment.Actions />
          </DocumentsAssignment.DetailsWrapper>
          <DocumentsAssignment.CancelHistory />
          <DocumentsAssignment.History />
        </DocumentsAssignment>
      );
    }
    case 'REVIEW_TASK': {
      return (
        <DocumentsAssignment>
          <DocumentsAssignment.DetailsWrapper>
            <DocumentsAssignment.DetailsHeader />
            <DocumentsAssignment.Content>
              <DocumentsAssignment.Employee />
              <DocumentsAssignment.Attributes />
              <DocumentsAssignment.CancelComment showComment={showCancellationComment} />
              <DocumentsAssignment.Files
                files={kedoReviewFiles}
                id={kedoReviewTaskId}
                type={KEDO_COPY_FILE_TYPES.KEDO_REVIEW_TASK}
              />
            </DocumentsAssignment.Content>
            <DocumentsAssignment.Actions />
          </DocumentsAssignment.DetailsWrapper>
          <DocumentsAssignment.CancelHistory />
          <DocumentsAssignment.History />
        </DocumentsAssignment>
      );
    }
    case 'WORK_TOUR': {
      return (
        <DocumentsAssignment>
          <DocumentsAssignment.DetailsWrapper>
            <DocumentsAssignment.DetailsHeader />
            <DocumentsAssignment.Content>
              <DocumentsAssignment.Employee />
              <WorkTourDetails workTour={assignment.workTour} />
              <DocumentsAssignment.Files
                files={workTourFiles as KedoTaskFile[]}
                id={workTourId}
                type={KEDO_COPY_FILE_TYPES.WORK_TOUR}
              />
              <DocumentsAssignment.CancelComment showComment={showCancellationComment} />
            </DocumentsAssignment.Content>
            <DocumentsAssignment.Actions />
          </DocumentsAssignment.DetailsWrapper>
          <DocumentsAssignment.CancelHistory />
          <DocumentsAssignment.History history={assignment?.workTour?.history} />
        </DocumentsAssignment>
      );
    }
    case 'KEDO_TASK':
    default: {
      return (
        <DocumentsAssignment>
          <DocumentsAssignment.DetailsWrapper>
            <DocumentsAssignment.DetailsHeader />
            <DocumentsAssignment.Content>
              <DocumentsAssignment.Employee />
              <DocumentsAssignment.Attributes />
              <DocumentsAssignment.Files
                files={filesToShow}
                id={kedoTaskId}
                type={KEDO_COPY_FILE_TYPES.KEDO_TASK}
              />
              <DocumentsAssignment.CancelComment showComment={showCancellationComment} />
            </DocumentsAssignment.Content>
            <DocumentsAssignment.Actions />
          </DocumentsAssignment.DetailsWrapper>
          <DocumentsAssignment.CancelHistory />
          <DocumentsAssignment.History />
        </DocumentsAssignment>
      );
    }
  }
};

export default DocumentsAssignmentDetails;
