import { useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { createMediaUploadUrl } from 'utils/createMediaUrl.utils';
import { userManager } from 'utils/keycloak';

import { UploadProps } from 'gazprom-ui-lib';

export type UploadedFileType = { name: string; key: string; fileName: string; url: string };
interface UseUploadFileProps {
  setErrors: (errors: string[]) => void;
  handleFileSet: (params: UploadedFileType) => void;
  skipMediaPrefix?: boolean;
  initialValue?: UploadedFileType;
}

const useUploadFile = (props: UseUploadFileProps) => {
  const { setErrors, handleFileSet, skipMediaPrefix = false, initialValue } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [fileData, setFileData] = useState<{
    fileName?: string;
    name?: string;
    url?: string;
    key?: string;
  }>({});

  const getStatus = async (
    key: string,
  ): Promise<{ status?: string; error: string; fileName: string }> => {
    const user = await userManager.getUser();
    const token = user?.access_token as string;

    return fetch(`${process.env.https://api-test.dev.tockl.ru/api/v1/}me/files/meta/${key}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 'VERIFIED') {
          return response;
        } else if (response.status === 'INFECTED') {
          throw new Error('infected file');
        } else {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve(getStatus(key));
            }, 1000);
          });
        }
      })
      .catch((error) => {
        console.error('Request failed:', error);
        throw error;
      });
  };

  const uploadData = (file: File, token: string) => {
    const formData = new FormData();
    formData.append('file', file);

    return fetch(`${process.env.https://api-test.dev.tockl.ru/api/v1/}me/files`, {
      method: 'POST',
      credentials: 'include',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => res.json());
  };

  const customRequest: UploadProps['customRequest'] = async (e) => {
    setIsLoading(true);
    setFileData({});

    const user = await userManager.getUser();
    const token = user?.access_token as string;
    const typedFile = e.file as File;
    uploadData(typedFile, token)
      .then(async (res) => {
        const fileKey = res.fileKey;
        const status = await getStatus(fileKey);
        const params = {
          name: typedFile.name,
          key: fileKey,
          fileName: status?.fileName,
          url: skipMediaPrefix ? fileKey : createMediaUploadUrl(fileKey),
        };

        setFileData(params);
        handleFileSet(params);
      })
      .catch(() => {
        setErrors([t('validation_file_something_went_wrong')]);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (initialValue) {
      setFileData(initialValue);
    }
  }, [initialValue, setFileData]);

  return {
    isLoading,
    fileData,
    customRequest,
    setFileData,
  };
};

export default useUploadFile;
