import {
  MORE_EDUCATION_BDD_ROUTE,
  MORE_EDUCATION_FIRE_SECURITY_ROUTE,
  MORE_EDUCATION_MANUFACTURING_JOBS_ROUTE,
  MORE_EDUCATION_WORK_SECURITY_ROUTE,
} from 'routes/more/list';
import { t } from 'tools/i18n';

import { TIconsLiteral } from 'gazprom-ui-lib';

export type EducationSectionVideoType = {
  title: string;
  url: string;
};

export type EducationSectionType = {
  link: string;
  title: string;
  sectionTitle?: string;
  description: string;
  icon: TIconsLiteral;
  items: EducationSectionVideoType[];
};

export const EDUCATION_SECTIONS: EducationSectionType[] = [
  {
    link: MORE_EDUCATION_BDD_ROUTE,
    title: t('education_bdd_title'),
    description: t('education_bdd_description'),
    icon: 'safetyRoad' as TIconsLiteral,
    items: [
      {
        title: t('education_bdd_item1_title'),
        url: 'https://youtu.be/o3t1WJSlSK4',
      },
      {
        title: t('education_bdd_item2_title'),
        url: 'https://www.youtube.com/watch?v=UGPaD7GlAgM',
      },
      {
        title: t('education_bdd_item3_title'),
        url: 'https://www.youtube.com/watch?v=mlloafzcsEw',
      },
      {
        title: t('education_bdd_item4_title'),
        url: 'https://www.youtube.com/watch?v=EUvgbg3mGRM',
      },
      {
        title: t('education_bdd_item5_title'),
        url: 'https://www.youtube.com/watch?v=-xKqu2K6tnU',
      },
    ],
  },
  {
    link: MORE_EDUCATION_WORK_SECURITY_ROUTE,
    title: t('education_work_security_title'),
    description: t('education_work_security_description'),
    sectionTitle: t('education_work_security_section_title'),
    icon: 'occupationSafety' as TIconsLiteral,
    items: [
      {
        title: t('education_work_item1_title'),
        url: 'https://www.youtube.com/watch?v=Gpj-IaNRvxY',
      },
      {
        title: t('education_work_item2_title'),
        url: 'https://www.youtube.com/watch?v=kVXDr8S49To',
      },
    ],
  },
  {
    link: MORE_EDUCATION_FIRE_SECURITY_ROUTE,
    title: t('education_fire_security_title'),
    description: t('education_fire_security_description'),
    icon: 'safetyFire' as TIconsLiteral,
    items: [
      {
        title: t('education_fire_security_item1_title'),
        url: 'https://www.youtube.com/watch?v=8CzvPOzCreU',
      },
      {
        title: t('education_fire_security_item2_title'),
        url: 'https://www.youtube.com/watch?v=Gpj-IaNRvxY',
      },
    ],
  },
  {
    link: MORE_EDUCATION_MANUFACTURING_JOBS_ROUTE,
    title: t('education_manufacturing_jobs_title'),
    description: t('education_manufacturing_jobs_description'),
    icon: 'safetyWork' as TIconsLiteral,
    items: [
      {
        title: t('education_manufacturing_jobs_item1_title'),
        url: 'https://www.youtube.com/watch?v=c4UWV61wzRQ',
      },
      {
        title: t('education_manufacturing_jobs_item2_title'),
        url: 'https://www.youtube.com/watch?v=3ff1ED6Kk60',
      },
      {
        title: t('education_manufacturing_jobs_item3_title'),
        url: 'https://www.youtube.com/watch?v=eEpQbeYZZts',
      },
      {
        title: t('education_manufacturing_jobs_item4_title'),
        url: 'https://www.youtube.com/watch?v=yCPLW0hCvpg',
      },
      {
        title: t('education_manufacturing_jobs_item5_title'),
        url: 'https://www.youtube.com/watch?v=GFvb8pdDBRY',
      },
      {
        title: t('education_manufacturing_jobs_item6_title'),
        url: 'https://www.youtube.com/watch?v=XPuaihbJKVk',
      },
      {
        title: t('education_manufacturing_jobs_item7_title'),
        url: 'https://www.youtube.com/watch?v=wBCvWvTP1Rg',
      },
      {
        title: t('education_manufacturing_jobs_item8_title'),
        url: 'https://www.youtube.com/watch?v=cYyj1hPdtRY',
      },
      {
        title: t('education_manufacturing_jobs_item9_title'),
        url: 'https://www.youtube.com/watch?v=m0phy_9VpKM',
      },
      {
        title: t('education_manufacturing_jobs_item10_title'),
        url: 'https://www.youtube.com/watch?v=kOFyS0KB6xE',
      },
      {
        title: t('education_manufacturing_jobs_item11_title'),
        url: 'https://www.youtube.com/watch?v=YM2vVZF1SuQ',
      },
      {
        title: t('education_manufacturing_jobs_item12_title'),
        url: 'https://www.youtube.com/watch?v=IpJsAAvF5EE',
      },
    ],
  },
];
