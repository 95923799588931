import { TIconsLiteral } from 'gazprom-ui-lib';

import { FileType } from './file.types';

export enum KEDO_COPY_FILE_TYPES {
  KEDO_DOCUMENT = 'KEDO_DOCUMENT',
  KEDO_REVIEW_TASK = 'KEDO_REVIEW_TASK',
  KEDO_TASK = 'KEDO_TASK',
  PAYSLIP = 'PAYSLIP',
  WORK_TOUR = 'WORK_TOUR',
}

export enum TASK_STATUS_ENUM {
  ALL = 'ALL',
  NEW = 'NEW',
  SENT = 'SENT',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  KEDO_DECLINED_1C = 'KEDO_DECLINED_1C',
  KEDO_CORRECTION_1C = 'KEDO_CORRECTION_1C',
}

export enum TASK_HISTORY_STATUS_ENUM {
  CREATED = 'CREATED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PROVIDED = 'PROVIDED',
  DELIVERING = 'DELIVERING',
  DOCS_CHECKING = 'DOCS_CHECKING',
  DOCS_FAILED = 'DOCS_FAILED',
  DOCS_ACCEPTED = 'DOCS_ACCEPTED',
  NEED_CORRECTION = 'NEED_CORRECTION',
  IN_REVIEW = 'IN_REVIEW',
  REVIEWED = 'REVIEWED',
  BPMN_CORRECTION_1C = 'BPMN_CORRECTION_1C',
  SOCIAL_RESEND = 'SOCIAL_RESEND',
  BPMN_RESEND = 'BPMN_RESEND',
  BPMN_READY = 'BPMN_READY',
  HR_EXECUTED = 'HR_EXECUTED',
  DRAFT = 'DRAFT',
}
export enum STATEMENT_ATTRIBUTE_TYPE_ENUM {
  YEARS_LIST = 'YEARS_LIST',
  STRING = 'STRING',
  DATE = 'DATE',
  FILE = 'FILE',
  TEXT_AREA = 'TEXT_AREA',
}

export enum STATEMENT_CREATOR_TYPE_ENUM {
  HUMAN = 'human',
  APP = 'app',
}

export enum TASK_TYPE_ENUM {
  KEDO = 'KEDO',
  SOCIAL = 'SOCIAL',
  BPMN = 'BPMN',
}

export enum TASK_KEDO_STATES {
  ERROR = 'error',
}

export enum DATE_VALIDATION_ENUM {
  NO_LATER_CURRENT = 'NO_LATER_CURRENT',
  NO_LATER_3_DAYS = 'NO_LATER_3_DAYS',
  NO_LATER_5_DAYS = 'NO_LATER_5_DAYS',
  NO_LATER_7_DAYS = 'NO_LATER_7_DAYS',
  NO_LESS_THAN_3_DAYS = 'NO_LESS_THAN_3_DAYS',
  NO_LESS_THAN_5_DAYS = 'NO_LESS_THAN_5_DAYS',
  NO_LESS_THAN_7_DAYS = 'NO_LESS_THAN_7_DAYS',
}

export enum TASK_CODE_ENUM {
  ZAYAVLENIE_NA_IZMENENIE_PERSONALNYKH_DANNYKH = 'zayavlenieNaIzmeneniePersonalnykhDannykh',
}

export type StatementAttributeValidationCodes = DATE_VALIDATION_ENUM;

export type StatementAttributeValidation = {
  attributeType: string;
  code: StatementAttributeValidationCodes;
  errorText: string;
  title: string;
};

export type KedoStatementAttribute = {
  name: string;
  id: string;
  type: string;
  placeholder: string;
  tooltip: string;
  isMandatory: boolean;
  isPublish: boolean;
  inputMask?: string;
  code: string;
  createdAt: string;
  creator: string;
  editor: null;
  num: number;
  statementId: string;
  updatedAt: null | string;
  value: string;
  validation?: StatementAttributeValidation;
};

export type KedoStatement = {
  id: string;
  createdAt: string;
  updatedAt: string;
  creator: string;
  editor: string;
  categoryId: string;
  isPublished: boolean;
  name: string;
  description: string;
  attributes?: KedoStatementAttribute[];
  files: FileType[];
  kedoDraftId: string;
  kedoDraftUpdatedAt: string | null;
  fromId?: string;
  type: TASK_TYPE_ENUM;
  params?: { code: string; type: string; value: string }[];
  shippingAddress: string | null;
};

export type KedoDraftStatement = {
  id: string;
  createdAt: string;
  updatedAt: string;
  creator: string;
  editor: string;
  categoryId: string;
  categoryName: string;
  isPublish: boolean;
  name: string;
  description: string;
  role1CCode: string;
  hasCommission: boolean;
  type: string;
  defaultExecutor: string;
  code: string;
  params: {
    code: string;
    type: string;
    value: string;
  }[];
  isCancellable: boolean;
};

export type KedoCategory = {
  id: string;
  createdAt?: string;
  updatedAt?: string;
  creator?: string;
  editor?: string;
  name: string;
  isPublished?: boolean;
  icon?: null | TIconsLiteral;
};

export type KedoTaskFile = FileType & {
  type: KEDO_TASK_FILE_TYPES;
};

export type KedoTaskFilledAttribute = {
  attributeCode: string;
  attributeId: string;
  attributeName: string;
  num: number;
  userText: string;
  files?: KedoTaskFile[];
};

export type CurrentKedoStatusType = {
  comment: string | null;
  createdAt: string | null;
  description: string;
  state: string;
  status: TASK_HISTORY_STATUS_ENUM;
  title: string;
  titleAlt: null | string;
};

export type KedoTask = {
  id: string;
  socAmount: null | number;
  statement: KedoStatement;
  category: KedoCategory;
  updatedAt?: string;
  currentKedoStatus: CurrentKedoStatusType;
  newId?: string;
  type: string;
  currentStatus: TASK_STATUS_ENUM;
  files?: KedoTaskFile[];
  filledAttributes?: KedoTaskFilledAttribute[];
  history: {
    state: 'done' | 'pending' | 'error' | 'disabled';
    date: string;
    title: string;
    description?: string;
    createdAt?: string;
    status: TASK_HISTORY_STATUS_ENUM;
  }[];
  isCancellable: boolean;
  cancelHistory: {
    state: 'done' | 'pending' | 'error' | 'disabled';
    date: string;
    title: string;
    description?: string;
    createdAt?: string;
    status: string;
  }[];
  canCancelStatement: boolean;
  cancelStatementComment: string | null;
};

export type KedoReviewTaskStatus = {
  comment: string;
  createdAt?: string;
  description?: string;
  state: string;
  status: string;
  title?: string;
  titleAlt: string;
};

export type KedoReviewTask = {
  docName: string;
  files?: KedoTaskFile[];
  id: string;
  name: string;
  needEmployeeSign: boolean;
  sentDate: string;
  status: KedoReviewTaskStatus;
};

export type KedoDocument = {
  createDate: string;
  files?: KedoTaskFile[];
  id: string;
  name: string;
  status: KedoReviewTaskStatus;
};

export type KedoTaskDraft = {
  id: string;
  statement: KedoDraftStatement;
  createdAt: string;
  updatedAt?: string;
};

export type PreparedKedoTask = {
  taskId: string;
  filesToSign: {
    fileKey: string;
    fileName: string;
  }[];
};

export enum OPERATION_STATUS_ENUM {
  WRONG_CODE = '7',
  CANCELED_BY_USER = '3',
  CRASHED = '5',
  TIMEOUT = '4',
  CONFIRMATION_FAILED = '9',
  COMPLETED = '2',
  EXCEEDED_ATTEMPTS_COUNT = '8',
}

export type AssignedTaskTypes =
  | 'APPROVE'
  | 'SIGN_EMPLOYEE'
  | 'SIGN_REVIEW_EMPLOYEE'
  | 'INFO'
  | 'APPROVAL_MODIFY'
  | 'CANCEL_ORG_REQUEST'
  | 'EMPLOYMENT_SIGN_EMPLOYEE';

export type KedoStatusType = {
  code: string;
  description: string;
  isFilter: boolean;
  name: string;
  nameOwner: string | null;
  state: string;
  type: string;
};

export enum KEDO_TASK_FILE_TYPES {
  FINAL_DOC = 'FINAL_DOC',
  ORDER = 'ORDER',
  PETITION = 'PETITION',
}
