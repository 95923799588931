import React, { FC } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface CancelModalProps {
  isOpened: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const CancelModal: FC<CancelModalProps> = (props) => {
  const { onConfirm, isOpened, onCancel } = props;

  return (
    <ModalDefault open={isOpened} onCancel={onCancel} width={300}>
      <ModalDefault.Icon name="attention" type="warning" />
      <ModalDefault.Title>{t('documents_statement_cancel_title')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('documents_statement_cancel_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button size="large" fullWidth onClick={onConfirm}>
          {t('common_confirm')}
        </Button>
        <Button ghost size="large" fullWidth onClick={onCancel}>
          {t('common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default CancelModal;
