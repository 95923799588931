import React from 'react';

import Documents from 'pages/documents';
import DocumentsAssignmentDetails from 'pages/documents/documents-assignment-details';
import DocumentsBusinessTrip from 'pages/documents/documents-business-trip';
import DocumentsBusinessTripCreate from 'pages/documents/documents-business-trip/documents-business-trip-create';
import DocumentsBusinessTripDetails from 'pages/documents/documents-business-trip/documents-business-trip-details';
import DocumentsBusinessTripApproversList from 'pages/documents/documents-business-trip/documents-business-trip-details/documents-business-trip-approvers-list';
import DocumentsBusinessTripDetailsCancel from 'pages/documents/documents-business-trip/documents-business-trip-details/documents-business-trip-details-cancel';
import DocumentsBusinessTripDetailsEdit from 'pages/documents/documents-business-trip/documents-business-trip-details/documents-business-trip-details-edit';
import DocumentsCertificate from 'pages/documents/documents-certificate';
import DocumentsCreateCertificate from 'pages/documents/documents-create-certificate';
import DocumentsCreateChooseCategory from 'pages/documents/documents-create-choose-category';
import DocumentsCreateRequest from 'pages/documents/documents-create-request';
import DocumentsStatementSearch from 'pages/documents/documents-create-search-category';
import DocumentsInquiries from 'pages/documents/documents-inquiries';
import DocumentsRequestLeaders from 'pages/documents/documents-request-leaders';
import DocumentsRequestsDetails from 'pages/documents/documents-requests-details';
import DocumentsRequestsDetailsAttributes from 'pages/documents/documents-requests-details/documents-requests-details-attributes';
import DocumentsSignCode from 'pages/documents/documents-sign-code';
import DocumentsSignList from 'pages/documents/documents-sign-list';
import DocumentsSocialPolicy from 'pages/documents/documents-social-policy';
import DocumentsStatements from 'pages/documents/documents-statements';
import DocumentsTopManagement from 'pages/documents/documents-top-management';

import { RouteConfig } from '../routes';
import {
  DOCUMENTS_BUSINESS_TRIP_APPROVERS_LIST_ROUTE,
  DOCUMENTS_BUSINESS_TRIP_CREATE_ROUTE,
  DOCUMENTS_BUSINESS_TRIP_DETAILS_CANCEL_ROUTE,
  DOCUMENTS_BUSINESS_TRIP_DETAILS_EDIT_ROUTE,
  DOCUMENTS_BUSINESS_TRIP_DETAILS_ROUTE,
  DOCUMENTS_BUSINESS_TRIP_ROUTE,
  DOCUMENTS_CERTIFICATE_ROUTE,
  DOCUMENTS_CREATE_CERTIFICATES_ROUTE,
  DOCUMENTS_INQUIRIES_CHOOSE_CATEGORY_ROUTE,
  DOCUMENTS_INQUIRIES_DETAILS_ATTRIBUTES_ROUTE,
  DOCUMENTS_INQUIRIES_DETAILS_ROUTE,
  DOCUMENTS_INQUIRIES_LEADERS_ROUTE,
  DOCUMENTS_INQUIRIES_REQUEST_ROUTE,
  DOCUMENTS_INQUIRIES_ROUTE,
  DOCUMENTS_INQUIRIES_SEARCH_CATEGORY_ROUTE,
  DOCUMENTS_ROUTE,
  DOCUMENTS_SIGN_CODE_ROUTE,
  DOCUMENTS_SIGN_LIST_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_DETAILS_ATTRIBUTES_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_DETAILS_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_LEADERS_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_ROUTE,
  DOCUMENTS_STATEMENTS_DETAILS_ATTRIBUTES_ROUTE,
  DOCUMENTS_STATEMENTS_DETAILS_ROUTE,
  DOCUMENTS_STATEMENTS_LEADERS_ROUTE,
  DOCUMENTS_STATEMENTS_ROUTE,
  DOCUMENTS_STATEMENT_CHOOSE_CATEGORY_ROUTE,
  DOCUMENTS_STATEMENT_REQUEST_ROUTE,
  DOCUMENTS_STATEMENT_SEARCH_CATEGORY_ROUTE,
  DOCUMENTS_TASKS_DETAILS_ROUTE,
  DOCUMENTS_TOP_MANAGEMENT_BY_STAGE_ROUTE,
} from './list';

export const excludedRoutesForPreUser = [
  DOCUMENTS_INQUIRIES_ROUTE,
  DOCUMENTS_SOCIAL_POLICY_ROUTE,
  DOCUMENTS_STATEMENTS_ROUTE,
];

export const documentsRoutes: RouteConfig[] = [
  {
    path: DOCUMENTS_ROUTE,
    element: <Documents />,
  },
  {
    path: DOCUMENTS_INQUIRIES_ROUTE,
    element: <DocumentsInquiries />,
  },
  {
    path: DOCUMENTS_STATEMENTS_ROUTE,
    element: <DocumentsStatements />,
  },
  {
    path: DOCUMENTS_SOCIAL_POLICY_ROUTE,
    element: <DocumentsSocialPolicy />,
  },
  {
    path: DOCUMENTS_CERTIFICATE_ROUTE,
    element: <DocumentsCertificate />,
  },
  {
    path: DOCUMENTS_CREATE_CERTIFICATES_ROUTE,
    element: <DocumentsCreateCertificate />,
  },
  {
    path: DOCUMENTS_STATEMENT_CHOOSE_CATEGORY_ROUTE,
    element: <DocumentsCreateChooseCategory />,
  },
  {
    path: DOCUMENTS_INQUIRIES_CHOOSE_CATEGORY_ROUTE,
    element: <DocumentsCreateChooseCategory />,
  },
  {
    path: DOCUMENTS_STATEMENT_REQUEST_ROUTE,
    element: <DocumentsCreateRequest />,
  },
  {
    path: DOCUMENTS_INQUIRIES_REQUEST_ROUTE,
    element: <DocumentsCreateRequest />,
  },
  {
    path: DOCUMENTS_STATEMENT_SEARCH_CATEGORY_ROUTE,
    element: <DocumentsStatementSearch />,
  },
  {
    path: DOCUMENTS_INQUIRIES_SEARCH_CATEGORY_ROUTE,
    element: <DocumentsStatementSearch />,
  },
  {
    path: DOCUMENTS_SIGN_LIST_ROUTE,
    element: <DocumentsSignList />,
  },
  {
    path: DOCUMENTS_SIGN_CODE_ROUTE,
    element: <DocumentsSignCode />,
  },
  {
    path: DOCUMENTS_INQUIRIES_DETAILS_ROUTE,
    element: <DocumentsRequestsDetails />,
  },
  {
    path: DOCUMENTS_SOCIAL_POLICY_DETAILS_ROUTE,
    element: <DocumentsRequestsDetails />,
  },
  {
    path: DOCUMENTS_STATEMENTS_DETAILS_ROUTE,
    element: <DocumentsRequestsDetails />,
  },
  {
    path: DOCUMENTS_TASKS_DETAILS_ROUTE,
    element: <DocumentsAssignmentDetails />,
  },
  {
    path: DOCUMENTS_INQUIRIES_DETAILS_ATTRIBUTES_ROUTE,
    element: <DocumentsRequestsDetailsAttributes />,
  },
  {
    path: DOCUMENTS_SOCIAL_POLICY_DETAILS_ATTRIBUTES_ROUTE,
    element: <DocumentsRequestsDetailsAttributes />,
  },
  {
    path: DOCUMENTS_STATEMENTS_DETAILS_ATTRIBUTES_ROUTE,
    element: <DocumentsRequestsDetailsAttributes />,
  },
  {
    path: DOCUMENTS_INQUIRIES_LEADERS_ROUTE,
    element: <DocumentsRequestLeaders />,
  },
  {
    path: DOCUMENTS_SOCIAL_POLICY_LEADERS_ROUTE,
    element: <DocumentsRequestLeaders />,
  },
  {
    path: DOCUMENTS_STATEMENTS_LEADERS_ROUTE,
    element: <DocumentsRequestLeaders />,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIP_ROUTE,
    element: <DocumentsBusinessTrip />,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIP_CREATE_ROUTE,
    element: <DocumentsBusinessTripCreate />,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIP_DETAILS_ROUTE,
    element: <DocumentsBusinessTripDetails />,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIP_APPROVERS_LIST_ROUTE,
    element: <DocumentsBusinessTripApproversList />,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIP_DETAILS_EDIT_ROUTE,
    element: <DocumentsBusinessTripDetailsEdit />,
  },
  {
    path: DOCUMENTS_BUSINESS_TRIP_DETAILS_CANCEL_ROUTE,
    element: <DocumentsBusinessTripDetailsCancel />,
  },
  {
    path: DOCUMENTS_TOP_MANAGEMENT_BY_STAGE_ROUTE,
    element: <DocumentsTopManagement />,
  },
];
