import { useNavigate } from 'react-router-dom';
import {
  DOCUMENTS_SIGN_LIST_ROUTE,
  getDocumentsBusinessTripDetailsRoute,
} from 'routes/documents/list';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import initialApiService from 'services/initialApiService';
import { useSendBusinessTripMutation } from 'services/me/work-tour/workTourApiService';
import { PrepareBusinessTripResponseType } from 'services/me/work-tour/workTourApiService.types';
import { setActiveKedoTask } from 'slices/kedo';
import { useAppDispatch } from 'store';

import { handleRequest } from 'gazprom-ui-lib';

import { SERVICE_TAGS } from 'constants/serviceTags';

interface UseHandleCreateEditProps {
  onSuccess: (id: string) => void;
}

const useHandleCreateEdit = (props: UseHandleCreateEditProps) => {
  const { onSuccess } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedEmployee] = useFullSelectedEmployee();
  const [sendBusinessTrip, { isLoading: sendBusinessTripIsLoading }] =
    useSendBusinessTripMutation();

  const handleContinueBusinessTripCreateEdit = (businessTrip?: PrepareBusinessTripResponseType) => {
    if (businessTrip && selectedEmployee) {
      const { workTourId, filesToSign } = businessTrip;

      const isSignNeeded = !!filesToSign?.length;

      if (isSignNeeded) {
        const navigateParams = {
          state: {
            cameFrom: getDocumentsBusinessTripDetailsRoute(workTourId),
            modalType: 'created',
            invalidateTags: [SERVICE_TAGS.GET_BUSINESS_TRIPS],
            type: 'businessTrip',
            workTourId,
          },
        };

        dispatch(setActiveKedoTask(businessTrip));
        navigate(DOCUMENTS_SIGN_LIST_ROUTE, navigateParams);
      } else {
        sendBusinessTrip({
          myEmployeeId: selectedEmployee.id,
          id: workTourId,
          files: [],
        }).then(
          handleRequest({
            onSuccess: () => {
              dispatch(initialApiService?.util?.invalidateTags([SERVICE_TAGS.GET_BUSINESS_TRIPS]));
              onSuccess(workTourId);
            },
          }),
        );
      }
    }
  };

  return {
    isLoading: sendBusinessTripIsLoading,
    handleContinueBusinessTripCreateEdit,
  };
};

export default useHandleCreateEdit;
