import React, { Dispatch, SetStateAction } from 'react';

import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface DocumentsSignListModalProps {
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
}

const DocumentsSignListModal = (props: DocumentsSignListModalProps) => {
  const { isVisible, setIsVisible } = props;

  return (
    <ModalDefault open={isVisible}>
      <ModalDefault.Icon name="attention" type="warning" />
      <ModalDefault.Title>{t('common_error')}</ModalDefault.Title>
      <ModalDefault.Description> {t('common_error_description')}</ModalDefault.Description>
      <ModalDefault.Actions>
        {/*<Button type="primary" size="large" fullWidth onClick={() => {}} leftIcon={'refresh'}>*/}
        {/*  {t('common_retry_again')}*/}
        {/*</Button>*/}
        <Button type="secondary" size="large" fullWidth onClick={() => setIsVisible(false)}>
          {t('common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default DocumentsSignListModal;
