import React, { FC } from 'react';

import { useParams } from 'react-router-dom';
import { DOCUMENTS_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import { useGetKedoTaskLeadersQuery } from 'services/kedo/kedoApiService';

import { Card, Divider, Flex, Typography } from 'gazprom-ui-lib';

import ApprovalItem from 'containers/approval-item';
import Header from 'containers/header';
import WithBackground from 'containers/wrappers/with-background';
import WithLoader from 'containers/wrappers/with-loader';
import WithNavigation from 'containers/wrappers/with-navigation';
import WithStatusBar from 'containers/wrappers/with-status-bar';

import s from './DocumentsRequestLeaders.module.scss';

const DocumentsRequestLeaders: FC = () => {
  const params = useParams<{ taskId: string }>();

  const [selectedEmployee] = useFullSelectedEmployee();

  const { data: leaders, isFetching: leadersIsFetching } = useGetKedoTaskLeadersQuery(
    {
      id: params?.taskId!,
      myEmployeeId: selectedEmployee?.id!,
    },
    { skip: !params?.taskId || !selectedEmployee?.id },
  );

  const {
    person: firstLeaderPerson,
    position: firstLeaderPosition,
    status: firstLeaderStatus,
    finishDate: firstLeaderFinishDate,
  } = leaders?.firstLeader || {};

  const renderFirstLeaderCard = (
    <Flex vertical>
      <Divider />
      <Flex vertical gap="16" className={s.cardWrapper}>
        <Typography.Title>{t('documents_leaders_first_leader')}</Typography.Title>
        <ApprovalItem
          firstName={firstLeaderPerson?.firstName}
          lastName={firstLeaderPerson?.lastName}
          position={firstLeaderPosition ?? ''}
          status={firstLeaderStatus}
          finishDate={firstLeaderFinishDate ?? ''}
        />
      </Flex>
    </Flex>
  );

  const leadersCard = (
    <Flex vertical>
      <Divider />
      <Flex vertical gap="16" className={s.cardWrapper}>
        <Typography.Title>{t('documents_leaders_common')}</Typography.Title>
        {leaders?.leaders.map((leader) => {
          const { person, ...otherProps } = leader;

          return (
            <ApprovalItem
              key={person.id}
              firstName={person.firstName}
              lastName={person.lastName}
              {...otherProps}
            />
          );
        })}
      </Flex>
    </Flex>
  );

  const {
    person: functionalLeaderPerson,
    position: functionalPosition,
    status: functionalStatus,
    finishDate: functionalFinishDate,
  } = leaders?.functionalLeader || {};

  const renderFunctionalLeaderCard = (
    <Flex vertical>
      <Divider />
      <Flex vertical gap="16" className={s.cardWrapper}>
        <Typography.Title>{t('documents_leaders_functional_leader')}</Typography.Title>
        <ApprovalItem
          firstName={functionalLeaderPerson?.firstName}
          lastName={functionalLeaderPerson?.lastName}
          position={functionalPosition ?? ''}
          status={functionalStatus}
          finishDate={functionalFinishDate ?? ''}
        />
      </Flex>
    </Flex>
  );

  const headerChildren = (
    <>
      <Header.GoBack to={DOCUMENTS_ROUTE} />
      <Header.Label label={t('documents_leaders')} />
    </>
  );

  const leadersContent = (
    <>
      {leaders?.firstLeader && renderFirstLeaderCard}
      {!!leaders?.leaders.length && leadersCard}
      {leaders?.functionalLeader && renderFunctionalLeaderCard}
    </>
  );

  return (
    <WithStatusBar>
      <WithNavigation headerChildren={headerChildren}>
        <WithBackground>
          <Card withPadding={false}>
            <WithLoader isLoading={leadersIsFetching}>
              <Typography.Title className={s.title} level={3}>
                {t('documents_leaders')}
              </Typography.Title>
              <Flex vertical>{leadersContent}</Flex>
            </WithLoader>
          </Card>
        </WithBackground>
      </WithNavigation>
    </WithStatusBar>
  );
};

export default DocumentsRequestLeaders;
