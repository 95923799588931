import { useState } from 'react';

import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import { userManager } from 'utils/keycloak';
import { prepareQueryParams } from 'utils/prepareQueryParams';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useOpenFileByUrl from 'utils/useOpenFileByUrl.hook';

import { KEDO_COPY_FILE_TYPES } from 'types/documents.types';

interface UseDownloadKedoFileProps {
  fileKey: string;
  taskId: string;
  isArchive?: boolean;
  taskAssignmentId?: string;
  copyFileType?: KEDO_COPY_FILE_TYPES;
}

export type RequestBodyType = {
  type: KEDO_COPY_FILE_TYPES;
  fileKey: string;
  taskId?: string;
  kedoReviewTaskId?: string;
  payslipId?: string;
  taskAssignmentId?: string;
  kedoDocumentId?: string;
  workTourId?: string;
};

const useDownloadKedoFile = () => {
  const [selectedEmployee] = useFullSelectedEmployee();
  const [loadingKey, setLoadingKey] = useState<string>('');
  const { handleOpenFile } = useOpenFileByUrl();

  return {
    handleDownload:
      ({
        fileKey,
        taskId,
        isArchive,
        taskAssignmentId,
        copyFileType = KEDO_COPY_FILE_TYPES.KEDO_TASK,
      }: UseDownloadKedoFileProps) =>
      async () => {
        if (selectedEmployee) {
          setLoadingKey(fileKey);
          const user = await userManager.getUser();
          const token = user?.access_token as string;

          const requestBody = prepareDownloadRequestBody(
            fileKey,
            copyFileType,
            taskId,
            taskAssignmentId,
          );

          const url = `${
            process.env.https://api-test.dev.tockl.ru/
          }api/v1/kedo/me/copy-files${prepareQueryParams({ myEmployeeId: selectedEmployee?.id })}`;

          fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
          })
            .then((res) => res.json())
            .then((res) => {
              if (res.fileKey) {
                handleOpenFile({
                  url: createMediaDownloadUrl(
                    `api/v1/kedo-files/download/${res.fileKey}${prepareQueryParams({
                      full: !!isArchive,
                    })}`,
                  ),
                  withAuth: true,
                  fileKey,
                });
              }
            })
            .finally(() => {
              setLoadingKey('');
            });
        }
      },
    loadingKey,
  };
};

export const prepareDownloadRequestBody = (
  fileKey: string,
  type: KEDO_COPY_FILE_TYPES,
  taskId: string,
  taskAssignmentId?: string,
): RequestBodyType => {
  let requestBody: RequestBodyType = {
    fileKey,
    type,
  };

  switch (type) {
    case KEDO_COPY_FILE_TYPES.KEDO_DOCUMENT: {
      requestBody = {
        ...requestBody,
        kedoDocumentId: taskId,
        taskAssignmentId,
      };
      break;
    }
    case KEDO_COPY_FILE_TYPES.KEDO_REVIEW_TASK: {
      requestBody = {
        ...requestBody,
        kedoReviewTaskId: taskId,
        taskAssignmentId,
      };
      break;
    }
    case KEDO_COPY_FILE_TYPES.PAYSLIP: {
      requestBody = {
        ...requestBody,
        type: KEDO_COPY_FILE_TYPES.PAYSLIP,
        payslipId: taskId,
      };
      break;
    }
    case KEDO_COPY_FILE_TYPES.WORK_TOUR: {
      requestBody = {
        ...requestBody,
        type: KEDO_COPY_FILE_TYPES.WORK_TOUR,
        workTourId: taskId,
        taskAssignmentId,
      };
      break;
    }
    case KEDO_COPY_FILE_TYPES.KEDO_TASK:
    default: {
      requestBody = {
        ...requestBody,
        taskId: taskId,
        taskAssignmentId,
      };
    }
  }

  return requestBody;
};

export default useDownloadKedoFile;
