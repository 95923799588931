import React, { FC } from 'react';

import { useMatch } from 'react-router-dom';
import {
  DOCUMENTS_SOCIAL_POLICY_DETAILS_ROUTE,
  DOCUMENTS_STATEMENTS_DETAILS_ROUTE,
} from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface CreatedModalProps {
  isOpened: boolean;
  onCancel: () => void;
}

const CreatedModal: FC<CreatedModalProps> = (props) => {
  const { isOpened, onCancel } = props;
  const isSocialPolicyRoute = !!useMatch(DOCUMENTS_SOCIAL_POLICY_DETAILS_ROUTE);
  const isStatementRoute = !!useMatch(DOCUMENTS_STATEMENTS_DETAILS_ROUTE);

  let title = t('documents_statement_requested');
  let description = t('documents_statement_requested_description');

  if (isSocialPolicyRoute) {
    title = t('documents_sign_completed_tile');
    description = t('documents_sign_completed_description');
  }

  if (isStatementRoute) {
    title = t('documents_application_submitted');
    description = t('documents_application_submitted_description');
  }

  return (
    <ModalDefault open={isOpened} onCancel={onCancel} width={300}>
      <ModalDefault.Icon name="ok" type="success" />
      <ModalDefault.Title>{title}</ModalDefault.Title>
      <ModalDefault.Description>{description}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button size="large" fullWidth onClick={onCancel}>
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default CreatedModal;
